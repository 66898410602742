import React, { useContext, useState } from "react";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

export const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: "#fb4444",
  },
}));

export interface ISnackbarContext {
  error: (text: string) => void;
  info: (text: string) => void;
}

const SnackbarContext = React.createContext<ISnackbarContext>({
  error: () => {},
  info: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const classes = useStyles();
  const [_error, setError] = useState({ show: false, message: "" });
  const [_info, setInfo] = useState({ show: false, message: "" });

  function info(text: string) {
    setInfo({
      show: true,
      message: text,
    });
  }

  function error(text: string) {
    setError({
      show: true,
      message: text,
    });
  }

  function closeInfo() {
    setInfo({ show: false, message: "" });
  }

  function closeError() {
    setError({ show: false, message: "" });
  }

  return (
    <SnackbarContext.Provider
      value={{
        info,
        error,
      }}
    >
      {props.children}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={_error.show}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <SnackbarContent
          className={classes["error"]}
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{_error.message}</span>}
          action={
            <IconButton aria-label="close" color="inherit" onClick={closeError}>
              <Close />
            </IconButton>
          }
        />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={_info.show}
        autoHideDuration={6000}
        onClose={closeInfo}
      >
        <SnackbarContent
          className={classes["success"]}
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{_info.message}</span>}
          action={
            <IconButton aria-label="close" color="inherit" onClick={closeInfo}>
              <Close />
            </IconButton>
          }
        />
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
