import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoPlayer } from '../shared/VideoPlayer/VideoPlayer';
import { useGetHomeBannerOptionsQuery } from '../../graphql/generated/generated';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import Button from 'components/shared/Button/Button';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const useStyles = makeStyles((theme) => {
  return createStyles({
    banner: {
      position: 'relative',
      maxWidth: 1920,
      height: 'calc(100vh - 63px)',
      margin: '0 auto',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        height: 450,
      },

      '& img, & video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    bannerContent: {
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      '& p': {
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '22px',
        },
      },
      '& svg': {
        width: 350,
        height: 100,
        marginTop: '15px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
          width: 250,
          height: 60,
        },
      },
    },
    cta: {
      zIndex: 10,
      background: 'rgba(238, 238, 238, 0.7)',
      position: 'absolute',
      right: 20,
      top: 40,
      padding: '30px 40px',
      fontSize: 18,
      '&:hover': {
        color: '#000',
        background: 'rgba(238, 238, 238, 0.9)',
      },
    },
  });
});

interface Props {}

export const Banner: React.FC<Props> = () => {
  const classes = useStyles();

  const { data, error } = useGetHomeBannerOptionsQuery();

  if (error) return <ErrorMessage error={error} />;

  const bannerData = data?.themeOptions?.generalOptions?.homeOptions?.banner;

  const video = bannerData?.video;

  const image = bannerData?.image;

  const imgSrc =
    image?.sourceUrl || 'https://wallpaperaccess.com/full/434452.jpg';

  return (
    <div className={classes.banner}>
      {video ? (
        <VideoPlayer
          src={video}
          provider={video.includes('youtube') ? 'youtube' : 'html5'}
          options={{
            fullscreen: { enabled: true },
            muted: true,
            autoplay: true,
            loop: { active: true },
            resetOnEnd: true,
            controls: [''],
          }}
        />
      ) : image ? (
        <img src={imgSrc} alt="" />
      ) : null}
      <div className={classes.bannerContent}>
        <p>
          Welcome to <br />
          <Logo fill="#ffffff" />
        </p>
        <Button
          variant="outlined"
          to={`${bannerData?.button?.url}`}
          color="inherit"
          size="large"
        >
          {bannerData?.button?.title}
        </Button>
      </div>
    </div>
  );
};
