import React, { useEffect } from "react";
import { useRouter } from "lib/hooks/useRouter";
import Modal from "components/shared/Modal/Modal";

export interface Props {
  showCloseButton?: boolean;
  confirmationData: {
    text?: string;
    isOpen: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  };
}

export default function ConfirmationModal(props: Props) {
  const router = useRouter();

  useEffect(() => {
    cancel();
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirm = () => {
    if (props.confirmationData.onConfirm) {
      props.confirmationData.onConfirm();
    }
  };

  const cancel = () => {
    if (props.confirmationData.onCancel) {
      props.confirmationData.onCancel();
    }
  };

  return (
    <Modal
      open={props.confirmationData.isOpen}
      onClose={cancel}
      onConfirm={confirm}
      title={props.confirmationData.text}
      confirmLabel="PO"
      cancelLabel="JO"
    />
  );
}
