import React from "react";

type AnimateTypes = "right-to-left" | "bottom-up";

interface Props {
  children: React.ReactNode;
  animate?: AnimateTypes;
}

export const Animate = (props: Props) => {
  return (
    <span
      className={props.animate || "bottom-up"}
      style={{ display: "inline-block", width: "100%" }}
    >
      {props.children}
    </span>
  );
};
