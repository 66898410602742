import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Page } from 'components/shared/Page/Page';
import { GalleryComp as GalleryContent } from 'components/gallery/Gallery';

interface Props {}

export const Gallery: React.FC<RouteComponentProps<Props>> = () => {
  return (
    <Page animate>
      <GalleryContent />
    </Page>
  );
};
