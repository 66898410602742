import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { CheckoutFormik } from '../../lib/hooks/formik/useCheckoutFormik';

interface AddressFormProps {
  formik: CheckoutFormik;
}

export default function AddressForm(props: AddressFormProps) {
  const { values, errors, touched, handleChange, handleBlur } = props.formik;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            autoComplete="given-name"
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName ? errors.firstName : undefined}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            fullWidth
            autoComplete="family-name"
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName ? errors.lastName : undefined}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : undefined}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            fullWidth
            autoComplete="shipping address-line1"
            error={touched.address && !!errors.address}
            helperText={touched.address ? errors.address : undefined}
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            error={touched.city && !!errors.city}
            helperText={touched.city ? errors.city : undefined}
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Postal Code"
            fullWidth
            autoComplete="shipping postal-code"
            error={touched.zip && !!errors.zip}
            helperText={touched.zip ? errors.zip : undefined}
            value={values.zip}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            error={touched.country && !!errors.country}
            helperText={touched.country ? errors.country : undefined}
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
