import React from 'react';
import { Page } from 'components/shared/Page/Page';
import { Services } from 'components/home/Services';
// import { About } from 'components/home/About';
import { Banner } from '../../components/home/Banner';
import { GalleryHome } from 'components/home/GalleryHome';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  homePage: { paddingTop: 0 },
});
export const Home = () => {
  const classes = useStyles();
  return (
    <Page animate className={classes.homePage}>
      <Banner />

      <Services />
      <GalleryHome />
      {/* <About /> */}
    </Page>
  );
};
