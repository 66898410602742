import React from 'react';
import Gallery from 'react-photo-gallery';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../shared/Button/Button';
import Grid from '@material-ui/core/Grid';
import { SectionTitle } from '../shared/SectionTitle/SectionTitle';

const useStyles = makeStyles({
  gallery: {
    marginTop: '100px',
    padding: '0 20px',
    // background: '#f1f1f1',
  },
  homeGallery: {
    marginTop: 20,
  },
  homeGalleryAll: {
    width: '100%',
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '8px ​30px',
    },
  },
});

interface Props {}

const photos = [
  {
    src: 'https://source.unsplash.com/2ShvY8Lf6l0/800x599',
    width: 4,
    height: 3,
  },
  {
    src: 'https://source.unsplash.com/Dm-qxdynoEc/800x799',
    width: 1,
    height: 1,
  },
  {
    src: 'https://source.unsplash.com/qDkso9nvCg0/600x799',
    width: 3,
    height: 4,
  },
  {
    src: 'https://source.unsplash.com/iecJiKe_RNg/600x799',
    width: 3,
    height: 4,
  },
  {
    src: 'https://source.unsplash.com/epcsn8Ed8kY/600x799',
    width: 3,
    height: 4,
  },
  {
    src: 'https://source.unsplash.com/NQSWvyVRIJk/800x599',
    width: 4,
    height: 3,
  },
  {
    src: 'https://source.unsplash.com/zh7GEuORbUw/600x799',
    width: 3,
    height: 4,
  },
  {
    src: 'https://source.unsplash.com/PpOHJezOalU/800x599',
    width: 4,
    height: 3,
  },
  {
    src: 'https://source.unsplash.com/I1ASdgphUH4/800x599',
    width: 4,
    height: 3,
  },
];

export const GalleryHome: React.FC<Props> = (props) => {
  const classes = useStyles({});
  return (
    <div className={classes.gallery}>
      <Grid item style={{ width: '100%', padding: 0, marginBottom: 20 }}>
        <SectionTitle text="Gallery" style={{ textAlign: 'center' }} />
      </Grid>

      <div className={classes.homeGallery}>
        <Gallery photos={photos} />
      </div>
      <div className={classes.homeGalleryAll}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ borderColor: '#323232' }}
          to="/gallery"
        >
          see all
        </Button>
      </div>
    </div>
  );
};
