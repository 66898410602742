import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, IconButton, List, ListItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Menu } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import Button from 'components/shared/Button/Button';
import { useRouter } from '../../../../lib/hooks/useRouter';

const useStyles = makeStyles((theme) => {
  return createStyles({
    drawerPaper: {
      background: theme.palette.secondary.main,
    },
    mobileDrawerWrapper: {
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: 260,
      },
    },
    linkText: {
      marginBottom: 15,
      display: 'block',
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `black`,
      '& button': {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
      },
    },
    linkTextActive: {
      '& .MuiButtonBase-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
      },
    },
    bottomList: {
      marginTop: 'auto',

      '& .MuiListItem-root': {
        paddingBottom: 0,
        paddingTop: 0,
      },
      '& button': {
        width: '100%',
        justifyContent: 'flex-start',
      },
    },
  });
});

interface Props {
  navLinks: any;
}

const MobileDrawer = (props: Props) => {
  const classes = useStyles();
  const router = useRouter();
  const [state, setState] = useState(false);

  const { navLinks } = props;

  useEffect(() => {
    setState(false);
  }, [router.location.pathname, router.location.search]);

  const toggleDrawer = () => setState((s) => !s);

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer}>
        <Menu fontSize="large" />
      </IconButton>

      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.mobileDrawerWrapper}>
          {/*<IconButton edge="start" aria-label="menu" onClick={() => setState(false)}>*/}
          {/*  <Close fontSize="large" />*/}
          {/*</IconButton>*/}

          <div
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <List component="nav" style={{ marginTop: 30 }}>
              {navLinks.map((x: any) => (
                <NavLink
                  to={x.path}
                  key={x.title}
                  className={classes.linkText}
                  activeClassName={classes.linkTextActive}
                >
                  <Button variant="text">{x.title}</Button>
                </NavLink>
              ))}
            </List>
          </div>

          <div
            className={classes.bottomList}
            role="presentation"
            onClick={toggleDrawer}
          >
            <List>
              <>
                <ListItem disableGutters>
                  <Button
                    variant="text"
                    startIcon={<AccountCircle />}
                    to="/login"
                  >
                    Kyqu
                  </Button>
                </ListItem>

                <ListItem disableGutters>
                  <Button
                    variant="text"
                    startIcon={<AccountCircle />}
                    to="/register"
                  >
                    Regjistrohu
                  </Button>
                </ListItem>
              </>
            </List>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileDrawer;
