import { createMuiTheme } from '@material-ui/core/styles';

const MainTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },

  palette: {
    primary: {
      main: '#323232',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000',
      contrastText: '#000',
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'dense',
    },
    // MuiInputLabel: {
    //   shrink: true,
    // },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: '15px',
      },
    },
    MuiTextField: {
      root: {
        marginBottom: 10,
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          background: '#fff',
          bottom: -9,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 4,
      },
    },
  },
});

export default MainTheme;
