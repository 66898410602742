import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useGetHomeServicesQuery } from 'graphql/generated/generated';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';
import { makeStyles } from '@material-ui/core/styles';
import { SectionTitle } from '../shared/SectionTitle/SectionTitle';
import Container from 'components/shared/Container/Container';
import { ProductCategory } from 'components/product/ui/ProductCategory';

const useStyles = makeStyles({
  homeServices: {
    marginTop: '100px',
  },
  homeServicesAll: {
    width: '100%',
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '8px ​30px',
    },
  },
});

export const Services = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetHomeServicesQuery();

  if (error) return <ErrorMessage error={error} />;

  const services =
    data?.themeOptions?.generalOptions?.homeOptions?.services || [];

  return (
    <Container>
      <Grid container spacing={3} className={classes.homeServices}>
        <Grid item style={{ width: '100%', padding: 0, marginBottom: 20 }}>
          <SectionTitle text="SERVICES" style={{ textAlign: 'center' }} />
        </Grid>
        {(loading ? Array.from(new Array(2)) : services)?.map(
          (item, index: number) => {
            return (
              <ProductCategory
                key={`service-list-item-${index}`}
                loading={loading}
                item={item}
              />
            );
          }
        )}
      </Grid>
    </Container>
  );
};
