import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Container from 'components/shared/Container/Container';
import { ReactComponent as Logo } from 'assets/images/logo-small.svg';
import { Facebook, YouTube, Instagram } from '@material-ui/icons';
import { ReactComponent as Tiktok } from 'assets/icons/tiktok.svg';
import { Animate } from 'components/shared/Animate/Animate';
import { Divider } from '../../shared/Divider/Divider';
import { useGetFooterOptionsQuery } from '../../../graphql/generated/generated';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      background: '#fff',
      textAlign: 'center',
      padding: '0 0 30px 0',
    },
    footerLinks: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
      '& ul': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '30px 0',
        '& li': {
          margin: '0 10px',
        },

        [theme.breakpoints.down('sm')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',

          '& li': {
            margin: '10px 0',
          },
        },
      },
    },
    internalLinks: {
      '& .MuiTypography-body1': {
        fontWeight: 500,
      },
      '& a:hover': {
        color: theme.palette.primary.light,
      },
    },
    socialMedia: {
      display: 'flex',
      '& li': {
        marginLeft: 18,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        padding: 5,
        '& a': {
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        },

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          '& svg': {
            color: '#fff',
          },
        },

        '&:first-child': {
          marginLeft: 0,
        },
      },

      '& svg': {
        width: '20px',
        height: '20px',
        color: theme.palette.primary.main,
      },
    },

    copyRight: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',

        '& ul': {
          marginTop: 20,
        },
      },
      '& p': {
        fontWeight: 400,
      },
    },
  })
);
const Footer = () => {
  const classes = useStyles();
  const { data, error } = useGetFooterOptionsQuery();

  if (error) return <ErrorMessage error={error} />;

  const footerOptions = data?.themeOptions?.generalOptions?.footerOptions;

  const socialMediaLinks =
    data?.themeOptions?.generalOptions?.general?.socialMediaLinks;

  return (
    <Animate animate="bottom-up">
      <footer className={classes.footer}>
        <Container>
          <Divider />

          <Grid container spacing={4}>
            <Grid item md={6}>
              <Logo
                style={{
                  marginRight: 'auto',
                  display: 'block',
                  marginBottom: 15,
                  width: 150,
                  height: 40,
                }}
              />
              <Typography
                align="left"
                style={{ fontWeight: 300, fontSize: 15 }}
              >
                {footerOptions?.shortText}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <div className={classes.footerLinks}>
                <ul className={classes.internalLinks}>
                  <li>
                    <Link to="/about">
                      <Typography>About</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <Typography>Contact</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      <Typography>Terms and conditions </Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <Typography>Privacy policy </Typography>
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <div className={classes.copyRight}>
            <Typography>
              Gashi Media © {new Date().getFullYear()} – All right reserved
            </Typography>
            <ul className={classes.socialMedia}>
              <li>
                <a href={socialMediaLinks?.instagram || ''}>
                  <Instagram />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.linkedin || ''}>
                  <Tiktok width={18} height={18} />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.facebook || ''}>
                  <Facebook />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.youtube || ''}>
                  <YouTube />
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </Animate>
  );
};

export default Footer;
