
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Attribute": [
      "SimpleAttribute",
      "VariationAttribute"
    ],
    "CartError": [
      "CartItemError"
    ],
    "CartItemConnection": [
      "CartToCartItemConnection"
    ],
    "CartItemConnectionEdge": [
      "CartToCartItemConnectionEdge"
    ],
    "CategoryConnection": [
      "CategoryToAncestorsCategoryConnection",
      "CategoryToCategoryConnection",
      "PostToCategoryConnection",
      "RootQueryToCategoryConnection"
    ],
    "CategoryConnectionEdge": [
      "CategoryToAncestorsCategoryConnectionEdge",
      "CategoryToCategoryConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "PostToCategoryConnectionEdge",
      "RootQueryToCategoryConnectionEdge"
    ],
    "CommentConnection": [
      "CommentToCommentConnection",
      "MediaItemToCommentConnection",
      "OrderToCommentConnection",
      "PageToCommentConnection",
      "PostToCommentConnection",
      "ProductToCommentConnection",
      "ProductToCommentsConnection",
      "RootQueryToCommentConnection",
      "UserToCommentConnection"
    ],
    "CommentConnectionEdge": [
      "CommentToCommentConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "MediaItemToCommentConnectionEdge",
      "OrderToCommentConnectionEdge",
      "PageToCommentConnectionEdge",
      "PostToCommentConnectionEdge",
      "ProductToCommentConnectionEdge",
      "ProductToCommentsConnectionEdge",
      "RootQueryToCommentConnectionEdge",
      "UserToCommentConnectionEdge"
    ],
    "Commenter": [
      "CommentAuthor",
      "User"
    ],
    "CommenterConnectionEdge": [
      "CommentToCommenterConnectionEdge"
    ],
    "Connection": [
      "CartToCartItemConnection",
      "CategoryToAncestorsCategoryConnection",
      "CategoryToCategoryConnection",
      "CategoryToContentNodeConnection",
      "CategoryToPostConnection",
      "CommentToCommentConnection",
      "ContentNodeToEnqueuedScriptConnection",
      "ContentNodeToEnqueuedStylesheetConnection",
      "ContentTypeToContentNodeConnection",
      "ContentTypeToTaxonomyConnection",
      "CouponToCustomerConnection",
      "CouponToExcludedProductCategoriesConnection",
      "CouponToExcludedProductsConnection",
      "CouponToProductCategoryConnection",
      "CouponToProductConnection",
      "CustomerToDownloadableItemConnection",
      "CustomerToOrderConnection",
      "CustomerToRefundConnection",
      "ExternalProductToVariationAttributeConnection",
      "GlobalProductAttributeToTermNodeConnection",
      "GroupProductToProductConnection",
      "GroupProductToVariationAttributeConnection",
      "HierarchicalContentNodeToContentNodeAncestorsConnection",
      "HierarchicalContentNodeToContentNodeChildrenConnection",
      "MediaItemToCommentConnection",
      "MenuItemToMenuItemConnection",
      "MenuToMenuItemConnection",
      "OrderToCommentConnection",
      "OrderToCouponLineConnection",
      "OrderToDownloadableItemConnection",
      "OrderToFeeLineConnection",
      "OrderToLineItemConnection",
      "OrderToRefundConnection",
      "OrderToShippingLineConnection",
      "OrderToTaxLineConnection",
      "PageToCommentConnection",
      "PageToRevisionConnection",
      "PostFormatToContentNodeConnection",
      "PostFormatToPostConnection",
      "PostToCategoryConnection",
      "PostToCommentConnection",
      "PostToPostFormatConnection",
      "PostToRevisionConnection",
      "PostToTagConnection",
      "PostToTermNodeConnection",
      "ProductCategoryToAncestorsProductCategoryConnection",
      "ProductCategoryToContentNodeConnection",
      "ProductCategoryToProductCategoryConnection",
      "ProductCategoryToProductConnection",
      "ProductTagToContentNodeConnection",
      "ProductTagToProductConnection",
      "ProductToCommentConnection",
      "ProductToCommentsConnection",
      "ProductToGlobalProductAttributeConnection",
      "ProductToLocalProductAttributeConnection",
      "ProductToMediaItemConnection",
      "ProductToProductAttributeConnection",
      "ProductToProductCategoryConnection",
      "ProductToProductConnection",
      "ProductToProductTagConnection",
      "ProductToProductTypeConnection",
      "ProductToShippingClassConnection",
      "ProductToTermNodeConnection",
      "ProductToUpsellConnection",
      "ProductToVisibleProductConnection",
      "ProductTypeToContentNodeConnection",
      "ProductTypeToProductConnection",
      "ProductVariationToShippingClassConnection",
      "ProductVariationToVariationAttributeConnection",
      "ProductVariationToVisibleProductConnection",
      "RootQueryToCategoryConnection",
      "RootQueryToCommentConnection",
      "RootQueryToContentNodeConnection",
      "RootQueryToContentTypeConnection",
      "RootQueryToCouponConnection",
      "RootQueryToCustomerConnection",
      "RootQueryToEnqueuedScriptConnection",
      "RootQueryToEnqueuedStylesheetConnection",
      "RootQueryToGalleryConnection",
      "RootQueryToMediaItemConnection",
      "RootQueryToMenuConnection",
      "RootQueryToMenuItemConnection",
      "RootQueryToOrderConnection",
      "RootQueryToPageConnection",
      "RootQueryToPaymentGatewayConnection",
      "RootQueryToPluginConnection",
      "RootQueryToPostConnection",
      "RootQueryToPostFormatConnection",
      "RootQueryToProductCategoryConnection",
      "RootQueryToProductConnection",
      "RootQueryToProductTagConnection",
      "RootQueryToProductTypeConnection",
      "RootQueryToRefundConnection",
      "RootQueryToRevisionsConnection",
      "RootQueryToServiceConnection",
      "RootQueryToShippingClassConnection",
      "RootQueryToShippingMethodConnection",
      "RootQueryToTagConnection",
      "RootQueryToTaxRateConnection",
      "RootQueryToTaxonomyConnection",
      "RootQueryToTermNodeConnection",
      "RootQueryToTestimonialConnection",
      "RootQueryToThemeConnection",
      "RootQueryToUserConnection",
      "RootQueryToUserRoleConnection",
      "RootQueryToVisibleProductConnection",
      "ServiceToRevisionConnection",
      "ShippingClassToContentNodeConnection",
      "ShippingClassToProductConnection",
      "SimpleProductToProductConnection",
      "SimpleProductToVariationAttributeConnection",
      "TagToContentNodeConnection",
      "TagToPostConnection",
      "TaxonomyToContentTypeConnection",
      "TermNodeToEnqueuedScriptConnection",
      "TermNodeToEnqueuedStylesheetConnection",
      "UserToCommentConnection",
      "UserToEnqueuedScriptConnection",
      "UserToEnqueuedStylesheetConnection",
      "UserToMediaItemConnection",
      "UserToPageConnection",
      "UserToPostConnection",
      "UserToRevisionsConnection",
      "UserToServiceConnection",
      "UserToUserRoleConnection",
      "VariableProductToProductConnection",
      "VariableProductToProductVariationConnection",
      "VariableProductToVariationAttributeConnection",
      "VisibleProductToContentNodeConnection",
      "VisibleProductToProductConnection"
    ],
    "ContentNode": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "ProductVariation",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "ContentNodeConnection": [
      "CategoryToContentNodeConnection",
      "ContentTypeToContentNodeConnection",
      "HierarchicalContentNodeToContentNodeAncestorsConnection",
      "HierarchicalContentNodeToContentNodeChildrenConnection",
      "PostFormatToContentNodeConnection",
      "ProductCategoryToContentNodeConnection",
      "ProductTagToContentNodeConnection",
      "ProductTypeToContentNodeConnection",
      "RootQueryToContentNodeConnection",
      "RootQueryToRevisionsConnection",
      "ShippingClassToContentNodeConnection",
      "TagToContentNodeConnection",
      "UserToRevisionsConnection",
      "VisibleProductToContentNodeConnection"
    ],
    "ContentNodeConnectionEdge": [
      "CategoryToContentNodeConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "ContentTypeToContentNodeConnectionEdge",
      "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge",
      "HierarchicalContentNodeToContentNodeChildrenConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "PostFormatToContentNodeConnectionEdge",
      "ProductCategoryToContentNodeConnectionEdge",
      "ProductTagToContentNodeConnectionEdge",
      "ProductTypeToContentNodeConnectionEdge",
      "RootQueryToContentNodeConnectionEdge",
      "RootQueryToRevisionsConnectionEdge",
      "ShippingClassToContentNodeConnectionEdge",
      "TagToContentNodeConnectionEdge",
      "UserToRevisionsConnectionEdge",
      "VisibleProductToContentNodeConnectionEdge"
    ],
    "ContentTemplate": [
      "DefaultTemplate"
    ],
    "ContentTypeConnection": [
      "RootQueryToContentTypeConnection",
      "TaxonomyToContentTypeConnection"
    ],
    "ContentTypeConnectionEdge": [
      "ContentNodeToContentTypeConnectionEdge",
      "RootQueryToContentTypeConnectionEdge",
      "TaxonomyToContentTypeConnectionEdge"
    ],
    "CouponConnection": [
      "RootQueryToCouponConnection"
    ],
    "CouponConnectionEdge": [
      "RootQueryToCouponConnectionEdge"
    ],
    "CouponLineConnection": [
      "OrderToCouponLineConnection"
    ],
    "CouponLineConnectionEdge": [
      "OrderToCouponLineConnectionEdge"
    ],
    "CustomerConnection": [
      "CouponToCustomerConnection",
      "RootQueryToCustomerConnection"
    ],
    "CustomerConnectionEdge": [
      "CouponToCustomerConnectionEdge",
      "RootQueryToCustomerConnectionEdge"
    ],
    "DatabaseIdentifier": [
      "Category",
      "Comment",
      "CommentAuthor",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "Service",
      "ShippingClass",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "User",
      "VariableProduct",
      "VisibleProduct"
    ],
    "DownloadableItemConnection": [
      "CustomerToDownloadableItemConnection",
      "OrderToDownloadableItemConnection"
    ],
    "DownloadableItemConnectionEdge": [
      "CustomerToDownloadableItemConnectionEdge",
      "OrderToDownloadableItemConnectionEdge"
    ],
    "Edge": [
      "CartItemToProductConnectionEdge",
      "CartItemToProductVariationConnectionEdge",
      "CartToCartItemConnectionEdge",
      "CategoryToAncestorsCategoryConnectionEdge",
      "CategoryToCategoryConnectionEdge",
      "CategoryToContentNodeConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "CategoryToPostConnectionEdge",
      "CategoryToTaxonomyConnectionEdge",
      "CommentToCommentConnectionEdge",
      "CommentToCommenterConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "ContentNodeToContentTypeConnectionEdge",
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "ContentNodeToEnqueuedScriptConnectionEdge",
      "ContentNodeToEnqueuedStylesheetConnectionEdge",
      "ContentTypeToContentNodeConnectionEdge",
      "ContentTypeToTaxonomyConnectionEdge",
      "CouponToCustomerConnectionEdge",
      "CouponToExcludedProductCategoriesConnectionEdge",
      "CouponToExcludedProductsConnectionEdge",
      "CouponToProductCategoryConnectionEdge",
      "CouponToProductConnectionEdge",
      "CustomerToDownloadableItemConnectionEdge",
      "CustomerToOrderConnectionEdge",
      "CustomerToRefundConnectionEdge",
      "ExternalProductToVariationAttributeConnectionEdge",
      "GalleryToPreviewConnectionEdge",
      "GlobalProductAttributeToTermNodeConnectionEdge",
      "GroupProductToProductConnectionEdge",
      "GroupProductToVariationAttributeConnectionEdge",
      "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge",
      "HierarchicalContentNodeToContentNodeChildrenConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "LineItemToProductConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "MediaItemToCommentConnectionEdge",
      "MenuItemToMenuConnectionEdge",
      "MenuItemToMenuItemConnectionEdge",
      "MenuItemToMenuItemLinkableConnectionEdge",
      "MenuToMenuItemConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "OrderToCommentConnectionEdge",
      "OrderToCouponLineConnectionEdge",
      "OrderToDownloadableItemConnectionEdge",
      "OrderToFeeLineConnectionEdge",
      "OrderToLineItemConnectionEdge",
      "OrderToRefundConnectionEdge",
      "OrderToShippingLineConnectionEdge",
      "OrderToTaxLineConnectionEdge",
      "PageToCommentConnectionEdge",
      "PageToPreviewConnectionEdge",
      "PageToRevisionConnectionEdge",
      "PostFormatToContentNodeConnectionEdge",
      "PostFormatToPostConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "PostToCategoryConnectionEdge",
      "PostToCommentConnectionEdge",
      "PostToPostFormatConnectionEdge",
      "PostToPreviewConnectionEdge",
      "PostToRevisionConnectionEdge",
      "PostToTagConnectionEdge",
      "PostToTermNodeConnectionEdge",
      "ProductCategoryToAncestorsProductCategoryConnectionEdge",
      "ProductCategoryToContentNodeConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToProductCategoryConnectionEdge",
      "ProductCategoryToProductConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToContentNodeConnectionEdge",
      "ProductTagToProductConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductToCommentConnectionEdge",
      "ProductToCommentsConnectionEdge",
      "ProductToGlobalProductAttributeConnectionEdge",
      "ProductToLocalProductAttributeConnectionEdge",
      "ProductToMediaItemConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductToProductAttributeConnectionEdge",
      "ProductToProductCategoryConnectionEdge",
      "ProductToProductConnectionEdge",
      "ProductToProductTagConnectionEdge",
      "ProductToProductTypeConnectionEdge",
      "ProductToShippingClassConnectionEdge",
      "ProductToTermNodeConnectionEdge",
      "ProductToUpsellConnectionEdge",
      "ProductToVisibleProductConnectionEdge",
      "ProductTypeToContentNodeConnectionEdge",
      "ProductTypeToProductConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "ProductVariationToShippingClassConnectionEdge",
      "ProductVariationToVariableProductConnectionEdge",
      "ProductVariationToVariationAttributeConnectionEdge",
      "ProductVariationToVisibleProductConnectionEdge",
      "RootQueryToCategoryConnectionEdge",
      "RootQueryToCommentConnectionEdge",
      "RootQueryToContentNodeConnectionEdge",
      "RootQueryToContentTypeConnectionEdge",
      "RootQueryToCouponConnectionEdge",
      "RootQueryToCustomerConnectionEdge",
      "RootQueryToEnqueuedScriptConnectionEdge",
      "RootQueryToEnqueuedStylesheetConnectionEdge",
      "RootQueryToGalleryConnectionEdge",
      "RootQueryToMediaItemConnectionEdge",
      "RootQueryToMenuConnectionEdge",
      "RootQueryToMenuItemConnectionEdge",
      "RootQueryToOrderConnectionEdge",
      "RootQueryToPageConnectionEdge",
      "RootQueryToPaymentGatewayConnectionEdge",
      "RootQueryToPluginConnectionEdge",
      "RootQueryToPostConnectionEdge",
      "RootQueryToPostFormatConnectionEdge",
      "RootQueryToProductCategoryConnectionEdge",
      "RootQueryToProductConnectionEdge",
      "RootQueryToProductTagConnectionEdge",
      "RootQueryToProductTypeConnectionEdge",
      "RootQueryToRefundConnectionEdge",
      "RootQueryToRevisionsConnectionEdge",
      "RootQueryToServiceConnectionEdge",
      "RootQueryToShippingClassConnectionEdge",
      "RootQueryToShippingMethodConnectionEdge",
      "RootQueryToTagConnectionEdge",
      "RootQueryToTaxRateConnectionEdge",
      "RootQueryToTaxonomyConnectionEdge",
      "RootQueryToTermNodeConnectionEdge",
      "RootQueryToTestimonialConnectionEdge",
      "RootQueryToThemeConnectionEdge",
      "RootQueryToUserConnectionEdge",
      "RootQueryToUserRoleConnectionEdge",
      "RootQueryToVisibleProductConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ServiceToRevisionConnectionEdge",
      "ShippingClassToContentNodeConnectionEdge",
      "ShippingClassToProductConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "SimpleProductToProductConnectionEdge",
      "SimpleProductToVariationAttributeConnectionEdge",
      "TagToContentNodeConnectionEdge",
      "TagToPostConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "TaxonomyToContentTypeConnectionEdge",
      "TermNodeToEnqueuedScriptConnectionEdge",
      "TermNodeToEnqueuedStylesheetConnectionEdge",
      "TestimonialToPreviewConnectionEdge",
      "UserToCommentConnectionEdge",
      "UserToEnqueuedScriptConnectionEdge",
      "UserToEnqueuedStylesheetConnectionEdge",
      "UserToMediaItemConnectionEdge",
      "UserToPageConnectionEdge",
      "UserToPostConnectionEdge",
      "UserToRevisionsConnectionEdge",
      "UserToServiceConnectionEdge",
      "UserToUserRoleConnectionEdge",
      "VariableProductToProductConnectionEdge",
      "VariableProductToProductVariationConnectionEdge",
      "VariableProductToVariationAttributeConnectionEdge",
      "VisibleProductToContentNodeConnectionEdge",
      "VisibleProductToProductConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "EnqueuedAsset": [
      "EnqueuedScript",
      "EnqueuedStylesheet"
    ],
    "EnqueuedScriptConnection": [
      "ContentNodeToEnqueuedScriptConnection",
      "RootQueryToEnqueuedScriptConnection",
      "TermNodeToEnqueuedScriptConnection",
      "UserToEnqueuedScriptConnection"
    ],
    "EnqueuedScriptConnectionEdge": [
      "ContentNodeToEnqueuedScriptConnectionEdge",
      "RootQueryToEnqueuedScriptConnectionEdge",
      "TermNodeToEnqueuedScriptConnectionEdge",
      "UserToEnqueuedScriptConnectionEdge"
    ],
    "EnqueuedStylesheetConnection": [
      "ContentNodeToEnqueuedStylesheetConnection",
      "RootQueryToEnqueuedStylesheetConnection",
      "TermNodeToEnqueuedStylesheetConnection",
      "UserToEnqueuedStylesheetConnection"
    ],
    "EnqueuedStylesheetConnectionEdge": [
      "ContentNodeToEnqueuedStylesheetConnectionEdge",
      "RootQueryToEnqueuedStylesheetConnectionEdge",
      "TermNodeToEnqueuedStylesheetConnectionEdge",
      "UserToEnqueuedStylesheetConnectionEdge"
    ],
    "FeeLineConnection": [
      "OrderToFeeLineConnection"
    ],
    "FeeLineConnectionEdge": [
      "OrderToFeeLineConnectionEdge"
    ],
    "GalleryConnection": [
      "RootQueryToGalleryConnection"
    ],
    "GalleryConnectionEdge": [
      "GalleryToPreviewConnectionEdge",
      "RootQueryToGalleryConnectionEdge"
    ],
    "GlobalProductAttributeConnection": [
      "ProductToGlobalProductAttributeConnection"
    ],
    "GlobalProductAttributeConnectionEdge": [
      "ProductToGlobalProductAttributeConnectionEdge"
    ],
    "HierarchicalContentNode": [
      "MediaItem",
      "Page"
    ],
    "HierarchicalNode": [
      "Category",
      "MediaItem",
      "Page",
      "ProductCategory"
    ],
    "HierarchicalTermNode": [
      "Category",
      "ProductCategory"
    ],
    "LineItemConnection": [
      "OrderToLineItemConnection"
    ],
    "LineItemConnectionEdge": [
      "OrderToLineItemConnectionEdge"
    ],
    "LocalProductAttributeConnection": [
      "ProductToLocalProductAttributeConnection"
    ],
    "LocalProductAttributeConnectionEdge": [
      "ProductToLocalProductAttributeConnectionEdge"
    ],
    "MediaItemConnection": [
      "ProductToMediaItemConnection",
      "RootQueryToMediaItemConnection",
      "UserToMediaItemConnection"
    ],
    "MediaItemConnectionEdge": [
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "ProductToMediaItemConnectionEdge",
      "RootQueryToMediaItemConnectionEdge",
      "UserToMediaItemConnectionEdge"
    ],
    "MenuConnection": [
      "RootQueryToMenuConnection"
    ],
    "MenuConnectionEdge": [
      "MenuItemToMenuConnectionEdge",
      "RootQueryToMenuConnectionEdge"
    ],
    "MenuItemConnection": [
      "MenuItemToMenuItemConnection",
      "MenuToMenuItemConnection",
      "RootQueryToMenuItemConnection"
    ],
    "MenuItemConnectionEdge": [
      "MenuItemToMenuItemConnectionEdge",
      "MenuToMenuItemConnectionEdge",
      "RootQueryToMenuItemConnectionEdge"
    ],
    "MenuItemLinkable": [
      "Category",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "ProductCategory",
      "ProductTag",
      "Service",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "VariableProduct"
    ],
    "MenuItemLinkableConnectionEdge": [
      "MenuItemToMenuItemLinkableConnectionEdge"
    ],
    "MenuItemObjectUnion": [
      "Post",
      "Page",
      "Service",
      "Testimonial",
      "Gallery",
      "Category",
      "Tag",
      "ProductCategory",
      "ProductTag"
    ],
    "Node": [
      "CartItem",
      "Category",
      "Comment",
      "CommentAuthor",
      "ContentType",
      "Coupon",
      "CouponLine",
      "Customer",
      "DownloadableItem",
      "EnqueuedScript",
      "EnqueuedStylesheet",
      "ExternalProduct",
      "FeeLine",
      "Gallery",
      "GlobalProductAttribute",
      "GroupProduct",
      "LineItem",
      "LocalProductAttribute",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Order",
      "Page",
      "PaymentGateway",
      "Plugin",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ProductVariation",
      "Refund",
      "Service",
      "ShippingClass",
      "ShippingLine",
      "ShippingMethod",
      "SimpleAttribute",
      "SimpleProduct",
      "Tag",
      "TaxLine",
      "TaxRate",
      "Taxonomy",
      "Testimonial",
      "Theme",
      "User",
      "UserRole",
      "VariableProduct",
      "VariationAttribute",
      "VisibleProduct"
    ],
    "NodeWithAuthor": [
      "MediaItem",
      "Page",
      "Post",
      "Service"
    ],
    "NodeWithComments": [
      "ExternalProduct",
      "GroupProduct",
      "MediaItem",
      "Order",
      "Page",
      "Post",
      "SimpleProduct",
      "VariableProduct"
    ],
    "NodeWithContentEditor": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithExcerpt": [
      "ExternalProduct",
      "GroupProduct",
      "Post",
      "SimpleProduct",
      "VariableProduct"
    ],
    "NodeWithFeaturedImage": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "ProductVariation",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithPageAttributes": [
      "Page"
    ],
    "NodeWithRevisions": [
      "Page",
      "Post",
      "Service"
    ],
    "NodeWithTemplate": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithTitle": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithTrackbacks": [
      "Post"
    ],
    "OneToOneConnection": [
      "CartItemToProductConnectionEdge",
      "CartItemToProductVariationConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "CategoryToTaxonomyConnectionEdge",
      "CommentToCommenterConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "ContentNodeToContentTypeConnectionEdge",
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "GalleryToPreviewConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "LineItemToProductConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "MenuItemToMenuConnectionEdge",
      "MenuItemToMenuItemLinkableConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "PageToPreviewConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "PostToPreviewConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "ProductVariationToVariableProductConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "TestimonialToPreviewConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "OrderConnection": [
      "CustomerToOrderConnection",
      "RootQueryToOrderConnection"
    ],
    "OrderConnectionEdge": [
      "CustomerToOrderConnectionEdge",
      "RootQueryToOrderConnectionEdge"
    ],
    "PageConnection": [
      "PageToRevisionConnection",
      "RootQueryToPageConnection",
      "UserToPageConnection"
    ],
    "PageConnectionEdge": [
      "PageToPreviewConnectionEdge",
      "PageToRevisionConnectionEdge",
      "RootQueryToPageConnectionEdge",
      "UserToPageConnectionEdge"
    ],
    "PaymentGatewayConnection": [
      "RootQueryToPaymentGatewayConnection"
    ],
    "PaymentGatewayConnectionEdge": [
      "RootQueryToPaymentGatewayConnectionEdge"
    ],
    "PluginConnection": [
      "RootQueryToPluginConnection"
    ],
    "PluginConnectionEdge": [
      "RootQueryToPluginConnectionEdge"
    ],
    "PostConnection": [
      "CategoryToPostConnection",
      "PostFormatToPostConnection",
      "PostToRevisionConnection",
      "RootQueryToPostConnection",
      "TagToPostConnection",
      "UserToPostConnection"
    ],
    "PostConnectionEdge": [
      "CategoryToPostConnectionEdge",
      "PostFormatToPostConnectionEdge",
      "PostToPreviewConnectionEdge",
      "PostToRevisionConnectionEdge",
      "RootQueryToPostConnectionEdge",
      "TagToPostConnectionEdge",
      "UserToPostConnectionEdge"
    ],
    "PostFormatConnection": [
      "PostToPostFormatConnection",
      "RootQueryToPostFormatConnection"
    ],
    "PostFormatConnectionEdge": [
      "PostToPostFormatConnectionEdge",
      "RootQueryToPostFormatConnectionEdge"
    ],
    "Previewable": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "Product": [
      "ExternalProduct",
      "GroupProduct",
      "SimpleProduct",
      "VariableProduct"
    ],
    "ProductAttribute": [
      "GlobalProductAttribute",
      "LocalProductAttribute"
    ],
    "ProductAttributeConnection": [
      "ProductToProductAttributeConnection"
    ],
    "ProductAttributeConnectionEdge": [
      "ProductToProductAttributeConnectionEdge"
    ],
    "ProductCategoryConnection": [
      "CouponToExcludedProductCategoriesConnection",
      "CouponToProductCategoryConnection",
      "ProductCategoryToAncestorsProductCategoryConnection",
      "ProductCategoryToProductCategoryConnection",
      "ProductToProductCategoryConnection",
      "RootQueryToProductCategoryConnection"
    ],
    "ProductCategoryConnectionEdge": [
      "CouponToExcludedProductCategoriesConnectionEdge",
      "CouponToProductCategoryConnectionEdge",
      "ProductCategoryToAncestorsProductCategoryConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToProductCategoryConnectionEdge",
      "ProductToProductCategoryConnectionEdge",
      "RootQueryToProductCategoryConnectionEdge"
    ],
    "ProductConnection": [
      "CouponToExcludedProductsConnection",
      "CouponToProductConnection",
      "GroupProductToProductConnection",
      "ProductCategoryToProductConnection",
      "ProductTagToProductConnection",
      "ProductToProductConnection",
      "ProductToUpsellConnection",
      "ProductTypeToProductConnection",
      "RootQueryToProductConnection",
      "ShippingClassToProductConnection",
      "SimpleProductToProductConnection",
      "VariableProductToProductConnection",
      "VisibleProductToProductConnection"
    ],
    "ProductConnectionEdge": [
      "CartItemToProductConnectionEdge",
      "CouponToExcludedProductsConnectionEdge",
      "CouponToProductConnectionEdge",
      "GroupProductToProductConnectionEdge",
      "LineItemToProductConnectionEdge",
      "ProductCategoryToProductConnectionEdge",
      "ProductTagToProductConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductToProductConnectionEdge",
      "ProductToUpsellConnectionEdge",
      "ProductTypeToProductConnectionEdge",
      "RootQueryToProductConnectionEdge",
      "ShippingClassToProductConnectionEdge",
      "SimpleProductToProductConnectionEdge",
      "VariableProductToProductConnectionEdge",
      "VisibleProductToProductConnectionEdge"
    ],
    "ProductTagConnection": [
      "ProductToProductTagConnection",
      "RootQueryToProductTagConnection"
    ],
    "ProductTagConnectionEdge": [
      "ProductToProductTagConnectionEdge",
      "RootQueryToProductTagConnectionEdge"
    ],
    "ProductTypeConnection": [
      "ProductToProductTypeConnection",
      "RootQueryToProductTypeConnection"
    ],
    "ProductTypeConnectionEdge": [
      "ProductToProductTypeConnectionEdge",
      "RootQueryToProductTypeConnectionEdge"
    ],
    "ProductVariationConnection": [
      "VariableProductToProductVariationConnection"
    ],
    "ProductVariationConnectionEdge": [
      "CartItemToProductVariationConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "VariableProductToProductVariationConnectionEdge"
    ],
    "RefundConnection": [
      "CustomerToRefundConnection",
      "OrderToRefundConnection",
      "RootQueryToRefundConnection"
    ],
    "RefundConnectionEdge": [
      "CustomerToRefundConnectionEdge",
      "OrderToRefundConnectionEdge",
      "RootQueryToRefundConnectionEdge"
    ],
    "ServiceConnection": [
      "RootQueryToServiceConnection",
      "ServiceToRevisionConnection",
      "UserToServiceConnection"
    ],
    "ServiceConnectionEdge": [
      "RootQueryToServiceConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ServiceToRevisionConnectionEdge",
      "UserToServiceConnectionEdge"
    ],
    "ShippingClassConnection": [
      "ProductToShippingClassConnection",
      "ProductVariationToShippingClassConnection",
      "RootQueryToShippingClassConnection"
    ],
    "ShippingClassConnectionEdge": [
      "ProductToShippingClassConnectionEdge",
      "ProductVariationToShippingClassConnectionEdge",
      "RootQueryToShippingClassConnectionEdge"
    ],
    "ShippingLineConnection": [
      "OrderToShippingLineConnection"
    ],
    "ShippingLineConnectionEdge": [
      "OrderToShippingLineConnectionEdge"
    ],
    "ShippingMethodConnection": [
      "RootQueryToShippingMethodConnection"
    ],
    "ShippingMethodConnectionEdge": [
      "RootQueryToShippingMethodConnectionEdge"
    ],
    "TagConnection": [
      "PostToTagConnection",
      "RootQueryToTagConnection"
    ],
    "TagConnectionEdge": [
      "PostToTagConnectionEdge",
      "RootQueryToTagConnectionEdge"
    ],
    "TaxLineConnection": [
      "OrderToTaxLineConnection"
    ],
    "TaxLineConnectionEdge": [
      "OrderToTaxLineConnectionEdge"
    ],
    "TaxRateConnection": [
      "RootQueryToTaxRateConnection"
    ],
    "TaxRateConnectionEdge": [
      "RootQueryToTaxRateConnectionEdge"
    ],
    "TaxonomyConnection": [
      "ContentTypeToTaxonomyConnection",
      "RootQueryToTaxonomyConnection"
    ],
    "TaxonomyConnectionEdge": [
      "CategoryToTaxonomyConnectionEdge",
      "ContentTypeToTaxonomyConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "RootQueryToTaxonomyConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "TermNode": [
      "Category",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ShippingClass",
      "Tag",
      "VisibleProduct"
    ],
    "TermNodeConnection": [
      "GlobalProductAttributeToTermNodeConnection",
      "PostToTermNodeConnection",
      "ProductToTermNodeConnection",
      "RootQueryToTermNodeConnection"
    ],
    "TermNodeConnectionEdge": [
      "GlobalProductAttributeToTermNodeConnectionEdge",
      "PostToTermNodeConnectionEdge",
      "ProductToTermNodeConnectionEdge",
      "RootQueryToTermNodeConnectionEdge"
    ],
    "TestimonialConnection": [
      "RootQueryToTestimonialConnection"
    ],
    "TestimonialConnectionEdge": [
      "RootQueryToTestimonialConnectionEdge",
      "TestimonialToPreviewConnectionEdge"
    ],
    "ThemeConnection": [
      "RootQueryToThemeConnection"
    ],
    "ThemeConnectionEdge": [
      "RootQueryToThemeConnectionEdge"
    ],
    "UniformResourceIdentifiable": [
      "Category",
      "ContentType",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ProductVariation",
      "Service",
      "ShippingClass",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "User",
      "VariableProduct",
      "VisibleProduct"
    ],
    "UserConnection": [
      "RootQueryToUserConnection"
    ],
    "UserConnectionEdge": [
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "RootQueryToUserConnectionEdge"
    ],
    "UserRoleConnection": [
      "RootQueryToUserRoleConnection",
      "UserToUserRoleConnection"
    ],
    "UserRoleConnectionEdge": [
      "RootQueryToUserRoleConnectionEdge",
      "UserToUserRoleConnectionEdge"
    ],
    "VariableProductConnectionEdge": [
      "ProductVariationToVariableProductConnectionEdge"
    ],
    "VariationAttributeConnection": [
      "ExternalProductToVariationAttributeConnection",
      "GroupProductToVariationAttributeConnection",
      "ProductVariationToVariationAttributeConnection",
      "SimpleProductToVariationAttributeConnection",
      "VariableProductToVariationAttributeConnection"
    ],
    "VariationAttributeConnectionEdge": [
      "ExternalProductToVariationAttributeConnectionEdge",
      "GroupProductToVariationAttributeConnectionEdge",
      "ProductVariationToVariationAttributeConnectionEdge",
      "SimpleProductToVariationAttributeConnectionEdge",
      "VariableProductToVariationAttributeConnectionEdge"
    ],
    "VisibleProductConnection": [
      "ProductToVisibleProductConnection",
      "ProductVariationToVisibleProductConnection",
      "RootQueryToVisibleProductConnection"
    ],
    "VisibleProductConnectionEdge": [
      "ProductToVisibleProductConnectionEdge",
      "ProductVariationToVisibleProductConnectionEdge",
      "RootQueryToVisibleProductConnectionEdge"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Attribute": [
      "SimpleAttribute",
      "VariationAttribute"
    ],
    "CartError": [
      "CartItemError"
    ],
    "CartItemConnection": [
      "CartToCartItemConnection"
    ],
    "CartItemConnectionEdge": [
      "CartToCartItemConnectionEdge"
    ],
    "CategoryConnection": [
      "CategoryToAncestorsCategoryConnection",
      "CategoryToCategoryConnection",
      "PostToCategoryConnection",
      "RootQueryToCategoryConnection"
    ],
    "CategoryConnectionEdge": [
      "CategoryToAncestorsCategoryConnectionEdge",
      "CategoryToCategoryConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "PostToCategoryConnectionEdge",
      "RootQueryToCategoryConnectionEdge"
    ],
    "CommentConnection": [
      "CommentToCommentConnection",
      "MediaItemToCommentConnection",
      "OrderToCommentConnection",
      "PageToCommentConnection",
      "PostToCommentConnection",
      "ProductToCommentConnection",
      "ProductToCommentsConnection",
      "RootQueryToCommentConnection",
      "UserToCommentConnection"
    ],
    "CommentConnectionEdge": [
      "CommentToCommentConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "MediaItemToCommentConnectionEdge",
      "OrderToCommentConnectionEdge",
      "PageToCommentConnectionEdge",
      "PostToCommentConnectionEdge",
      "ProductToCommentConnectionEdge",
      "ProductToCommentsConnectionEdge",
      "RootQueryToCommentConnectionEdge",
      "UserToCommentConnectionEdge"
    ],
    "Commenter": [
      "CommentAuthor",
      "User"
    ],
    "CommenterConnectionEdge": [
      "CommentToCommenterConnectionEdge"
    ],
    "Connection": [
      "CartToCartItemConnection",
      "CategoryToAncestorsCategoryConnection",
      "CategoryToCategoryConnection",
      "CategoryToContentNodeConnection",
      "CategoryToPostConnection",
      "CommentToCommentConnection",
      "ContentNodeToEnqueuedScriptConnection",
      "ContentNodeToEnqueuedStylesheetConnection",
      "ContentTypeToContentNodeConnection",
      "ContentTypeToTaxonomyConnection",
      "CouponToCustomerConnection",
      "CouponToExcludedProductCategoriesConnection",
      "CouponToExcludedProductsConnection",
      "CouponToProductCategoryConnection",
      "CouponToProductConnection",
      "CustomerToDownloadableItemConnection",
      "CustomerToOrderConnection",
      "CustomerToRefundConnection",
      "ExternalProductToVariationAttributeConnection",
      "GlobalProductAttributeToTermNodeConnection",
      "GroupProductToProductConnection",
      "GroupProductToVariationAttributeConnection",
      "HierarchicalContentNodeToContentNodeAncestorsConnection",
      "HierarchicalContentNodeToContentNodeChildrenConnection",
      "MediaItemToCommentConnection",
      "MenuItemToMenuItemConnection",
      "MenuToMenuItemConnection",
      "OrderToCommentConnection",
      "OrderToCouponLineConnection",
      "OrderToDownloadableItemConnection",
      "OrderToFeeLineConnection",
      "OrderToLineItemConnection",
      "OrderToRefundConnection",
      "OrderToShippingLineConnection",
      "OrderToTaxLineConnection",
      "PageToCommentConnection",
      "PageToRevisionConnection",
      "PostFormatToContentNodeConnection",
      "PostFormatToPostConnection",
      "PostToCategoryConnection",
      "PostToCommentConnection",
      "PostToPostFormatConnection",
      "PostToRevisionConnection",
      "PostToTagConnection",
      "PostToTermNodeConnection",
      "ProductCategoryToAncestorsProductCategoryConnection",
      "ProductCategoryToContentNodeConnection",
      "ProductCategoryToProductCategoryConnection",
      "ProductCategoryToProductConnection",
      "ProductTagToContentNodeConnection",
      "ProductTagToProductConnection",
      "ProductToCommentConnection",
      "ProductToCommentsConnection",
      "ProductToGlobalProductAttributeConnection",
      "ProductToLocalProductAttributeConnection",
      "ProductToMediaItemConnection",
      "ProductToProductAttributeConnection",
      "ProductToProductCategoryConnection",
      "ProductToProductConnection",
      "ProductToProductTagConnection",
      "ProductToProductTypeConnection",
      "ProductToShippingClassConnection",
      "ProductToTermNodeConnection",
      "ProductToUpsellConnection",
      "ProductToVisibleProductConnection",
      "ProductTypeToContentNodeConnection",
      "ProductTypeToProductConnection",
      "ProductVariationToShippingClassConnection",
      "ProductVariationToVariationAttributeConnection",
      "ProductVariationToVisibleProductConnection",
      "RootQueryToCategoryConnection",
      "RootQueryToCommentConnection",
      "RootQueryToContentNodeConnection",
      "RootQueryToContentTypeConnection",
      "RootQueryToCouponConnection",
      "RootQueryToCustomerConnection",
      "RootQueryToEnqueuedScriptConnection",
      "RootQueryToEnqueuedStylesheetConnection",
      "RootQueryToGalleryConnection",
      "RootQueryToMediaItemConnection",
      "RootQueryToMenuConnection",
      "RootQueryToMenuItemConnection",
      "RootQueryToOrderConnection",
      "RootQueryToPageConnection",
      "RootQueryToPaymentGatewayConnection",
      "RootQueryToPluginConnection",
      "RootQueryToPostConnection",
      "RootQueryToPostFormatConnection",
      "RootQueryToProductCategoryConnection",
      "RootQueryToProductConnection",
      "RootQueryToProductTagConnection",
      "RootQueryToProductTypeConnection",
      "RootQueryToRefundConnection",
      "RootQueryToRevisionsConnection",
      "RootQueryToServiceConnection",
      "RootQueryToShippingClassConnection",
      "RootQueryToShippingMethodConnection",
      "RootQueryToTagConnection",
      "RootQueryToTaxRateConnection",
      "RootQueryToTaxonomyConnection",
      "RootQueryToTermNodeConnection",
      "RootQueryToTestimonialConnection",
      "RootQueryToThemeConnection",
      "RootQueryToUserConnection",
      "RootQueryToUserRoleConnection",
      "RootQueryToVisibleProductConnection",
      "ServiceToRevisionConnection",
      "ShippingClassToContentNodeConnection",
      "ShippingClassToProductConnection",
      "SimpleProductToProductConnection",
      "SimpleProductToVariationAttributeConnection",
      "TagToContentNodeConnection",
      "TagToPostConnection",
      "TaxonomyToContentTypeConnection",
      "TermNodeToEnqueuedScriptConnection",
      "TermNodeToEnqueuedStylesheetConnection",
      "UserToCommentConnection",
      "UserToEnqueuedScriptConnection",
      "UserToEnqueuedStylesheetConnection",
      "UserToMediaItemConnection",
      "UserToPageConnection",
      "UserToPostConnection",
      "UserToRevisionsConnection",
      "UserToServiceConnection",
      "UserToUserRoleConnection",
      "VariableProductToProductConnection",
      "VariableProductToProductVariationConnection",
      "VariableProductToVariationAttributeConnection",
      "VisibleProductToContentNodeConnection",
      "VisibleProductToProductConnection"
    ],
    "ContentNode": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "ProductVariation",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "ContentNodeConnection": [
      "CategoryToContentNodeConnection",
      "ContentTypeToContentNodeConnection",
      "HierarchicalContentNodeToContentNodeAncestorsConnection",
      "HierarchicalContentNodeToContentNodeChildrenConnection",
      "PostFormatToContentNodeConnection",
      "ProductCategoryToContentNodeConnection",
      "ProductTagToContentNodeConnection",
      "ProductTypeToContentNodeConnection",
      "RootQueryToContentNodeConnection",
      "RootQueryToRevisionsConnection",
      "ShippingClassToContentNodeConnection",
      "TagToContentNodeConnection",
      "UserToRevisionsConnection",
      "VisibleProductToContentNodeConnection"
    ],
    "ContentNodeConnectionEdge": [
      "CategoryToContentNodeConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "ContentTypeToContentNodeConnectionEdge",
      "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge",
      "HierarchicalContentNodeToContentNodeChildrenConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "PostFormatToContentNodeConnectionEdge",
      "ProductCategoryToContentNodeConnectionEdge",
      "ProductTagToContentNodeConnectionEdge",
      "ProductTypeToContentNodeConnectionEdge",
      "RootQueryToContentNodeConnectionEdge",
      "RootQueryToRevisionsConnectionEdge",
      "ShippingClassToContentNodeConnectionEdge",
      "TagToContentNodeConnectionEdge",
      "UserToRevisionsConnectionEdge",
      "VisibleProductToContentNodeConnectionEdge"
    ],
    "ContentTemplate": [
      "DefaultTemplate"
    ],
    "ContentTypeConnection": [
      "RootQueryToContentTypeConnection",
      "TaxonomyToContentTypeConnection"
    ],
    "ContentTypeConnectionEdge": [
      "ContentNodeToContentTypeConnectionEdge",
      "RootQueryToContentTypeConnectionEdge",
      "TaxonomyToContentTypeConnectionEdge"
    ],
    "CouponConnection": [
      "RootQueryToCouponConnection"
    ],
    "CouponConnectionEdge": [
      "RootQueryToCouponConnectionEdge"
    ],
    "CouponLineConnection": [
      "OrderToCouponLineConnection"
    ],
    "CouponLineConnectionEdge": [
      "OrderToCouponLineConnectionEdge"
    ],
    "CustomerConnection": [
      "CouponToCustomerConnection",
      "RootQueryToCustomerConnection"
    ],
    "CustomerConnectionEdge": [
      "CouponToCustomerConnectionEdge",
      "RootQueryToCustomerConnectionEdge"
    ],
    "DatabaseIdentifier": [
      "Category",
      "Comment",
      "CommentAuthor",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "Service",
      "ShippingClass",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "User",
      "VariableProduct",
      "VisibleProduct"
    ],
    "DownloadableItemConnection": [
      "CustomerToDownloadableItemConnection",
      "OrderToDownloadableItemConnection"
    ],
    "DownloadableItemConnectionEdge": [
      "CustomerToDownloadableItemConnectionEdge",
      "OrderToDownloadableItemConnectionEdge"
    ],
    "Edge": [
      "CartItemToProductConnectionEdge",
      "CartItemToProductVariationConnectionEdge",
      "CartToCartItemConnectionEdge",
      "CategoryToAncestorsCategoryConnectionEdge",
      "CategoryToCategoryConnectionEdge",
      "CategoryToContentNodeConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "CategoryToPostConnectionEdge",
      "CategoryToTaxonomyConnectionEdge",
      "CommentToCommentConnectionEdge",
      "CommentToCommenterConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "ContentNodeToContentTypeConnectionEdge",
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "ContentNodeToEnqueuedScriptConnectionEdge",
      "ContentNodeToEnqueuedStylesheetConnectionEdge",
      "ContentTypeToContentNodeConnectionEdge",
      "ContentTypeToTaxonomyConnectionEdge",
      "CouponToCustomerConnectionEdge",
      "CouponToExcludedProductCategoriesConnectionEdge",
      "CouponToExcludedProductsConnectionEdge",
      "CouponToProductCategoryConnectionEdge",
      "CouponToProductConnectionEdge",
      "CustomerToDownloadableItemConnectionEdge",
      "CustomerToOrderConnectionEdge",
      "CustomerToRefundConnectionEdge",
      "ExternalProductToVariationAttributeConnectionEdge",
      "GalleryToPreviewConnectionEdge",
      "GlobalProductAttributeToTermNodeConnectionEdge",
      "GroupProductToProductConnectionEdge",
      "GroupProductToVariationAttributeConnectionEdge",
      "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge",
      "HierarchicalContentNodeToContentNodeChildrenConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "LineItemToProductConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "MediaItemToCommentConnectionEdge",
      "MenuItemToMenuConnectionEdge",
      "MenuItemToMenuItemConnectionEdge",
      "MenuItemToMenuItemLinkableConnectionEdge",
      "MenuToMenuItemConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "OrderToCommentConnectionEdge",
      "OrderToCouponLineConnectionEdge",
      "OrderToDownloadableItemConnectionEdge",
      "OrderToFeeLineConnectionEdge",
      "OrderToLineItemConnectionEdge",
      "OrderToRefundConnectionEdge",
      "OrderToShippingLineConnectionEdge",
      "OrderToTaxLineConnectionEdge",
      "PageToCommentConnectionEdge",
      "PageToPreviewConnectionEdge",
      "PageToRevisionConnectionEdge",
      "PostFormatToContentNodeConnectionEdge",
      "PostFormatToPostConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "PostToCategoryConnectionEdge",
      "PostToCommentConnectionEdge",
      "PostToPostFormatConnectionEdge",
      "PostToPreviewConnectionEdge",
      "PostToRevisionConnectionEdge",
      "PostToTagConnectionEdge",
      "PostToTermNodeConnectionEdge",
      "ProductCategoryToAncestorsProductCategoryConnectionEdge",
      "ProductCategoryToContentNodeConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToProductCategoryConnectionEdge",
      "ProductCategoryToProductConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToContentNodeConnectionEdge",
      "ProductTagToProductConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductToCommentConnectionEdge",
      "ProductToCommentsConnectionEdge",
      "ProductToGlobalProductAttributeConnectionEdge",
      "ProductToLocalProductAttributeConnectionEdge",
      "ProductToMediaItemConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductToProductAttributeConnectionEdge",
      "ProductToProductCategoryConnectionEdge",
      "ProductToProductConnectionEdge",
      "ProductToProductTagConnectionEdge",
      "ProductToProductTypeConnectionEdge",
      "ProductToShippingClassConnectionEdge",
      "ProductToTermNodeConnectionEdge",
      "ProductToUpsellConnectionEdge",
      "ProductToVisibleProductConnectionEdge",
      "ProductTypeToContentNodeConnectionEdge",
      "ProductTypeToProductConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "ProductVariationToShippingClassConnectionEdge",
      "ProductVariationToVariableProductConnectionEdge",
      "ProductVariationToVariationAttributeConnectionEdge",
      "ProductVariationToVisibleProductConnectionEdge",
      "RootQueryToCategoryConnectionEdge",
      "RootQueryToCommentConnectionEdge",
      "RootQueryToContentNodeConnectionEdge",
      "RootQueryToContentTypeConnectionEdge",
      "RootQueryToCouponConnectionEdge",
      "RootQueryToCustomerConnectionEdge",
      "RootQueryToEnqueuedScriptConnectionEdge",
      "RootQueryToEnqueuedStylesheetConnectionEdge",
      "RootQueryToGalleryConnectionEdge",
      "RootQueryToMediaItemConnectionEdge",
      "RootQueryToMenuConnectionEdge",
      "RootQueryToMenuItemConnectionEdge",
      "RootQueryToOrderConnectionEdge",
      "RootQueryToPageConnectionEdge",
      "RootQueryToPaymentGatewayConnectionEdge",
      "RootQueryToPluginConnectionEdge",
      "RootQueryToPostConnectionEdge",
      "RootQueryToPostFormatConnectionEdge",
      "RootQueryToProductCategoryConnectionEdge",
      "RootQueryToProductConnectionEdge",
      "RootQueryToProductTagConnectionEdge",
      "RootQueryToProductTypeConnectionEdge",
      "RootQueryToRefundConnectionEdge",
      "RootQueryToRevisionsConnectionEdge",
      "RootQueryToServiceConnectionEdge",
      "RootQueryToShippingClassConnectionEdge",
      "RootQueryToShippingMethodConnectionEdge",
      "RootQueryToTagConnectionEdge",
      "RootQueryToTaxRateConnectionEdge",
      "RootQueryToTaxonomyConnectionEdge",
      "RootQueryToTermNodeConnectionEdge",
      "RootQueryToTestimonialConnectionEdge",
      "RootQueryToThemeConnectionEdge",
      "RootQueryToUserConnectionEdge",
      "RootQueryToUserRoleConnectionEdge",
      "RootQueryToVisibleProductConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ServiceToRevisionConnectionEdge",
      "ShippingClassToContentNodeConnectionEdge",
      "ShippingClassToProductConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "SimpleProductToProductConnectionEdge",
      "SimpleProductToVariationAttributeConnectionEdge",
      "TagToContentNodeConnectionEdge",
      "TagToPostConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "TaxonomyToContentTypeConnectionEdge",
      "TermNodeToEnqueuedScriptConnectionEdge",
      "TermNodeToEnqueuedStylesheetConnectionEdge",
      "TestimonialToPreviewConnectionEdge",
      "UserToCommentConnectionEdge",
      "UserToEnqueuedScriptConnectionEdge",
      "UserToEnqueuedStylesheetConnectionEdge",
      "UserToMediaItemConnectionEdge",
      "UserToPageConnectionEdge",
      "UserToPostConnectionEdge",
      "UserToRevisionsConnectionEdge",
      "UserToServiceConnectionEdge",
      "UserToUserRoleConnectionEdge",
      "VariableProductToProductConnectionEdge",
      "VariableProductToProductVariationConnectionEdge",
      "VariableProductToVariationAttributeConnectionEdge",
      "VisibleProductToContentNodeConnectionEdge",
      "VisibleProductToProductConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "EnqueuedAsset": [
      "EnqueuedScript",
      "EnqueuedStylesheet"
    ],
    "EnqueuedScriptConnection": [
      "ContentNodeToEnqueuedScriptConnection",
      "RootQueryToEnqueuedScriptConnection",
      "TermNodeToEnqueuedScriptConnection",
      "UserToEnqueuedScriptConnection"
    ],
    "EnqueuedScriptConnectionEdge": [
      "ContentNodeToEnqueuedScriptConnectionEdge",
      "RootQueryToEnqueuedScriptConnectionEdge",
      "TermNodeToEnqueuedScriptConnectionEdge",
      "UserToEnqueuedScriptConnectionEdge"
    ],
    "EnqueuedStylesheetConnection": [
      "ContentNodeToEnqueuedStylesheetConnection",
      "RootQueryToEnqueuedStylesheetConnection",
      "TermNodeToEnqueuedStylesheetConnection",
      "UserToEnqueuedStylesheetConnection"
    ],
    "EnqueuedStylesheetConnectionEdge": [
      "ContentNodeToEnqueuedStylesheetConnectionEdge",
      "RootQueryToEnqueuedStylesheetConnectionEdge",
      "TermNodeToEnqueuedStylesheetConnectionEdge",
      "UserToEnqueuedStylesheetConnectionEdge"
    ],
    "FeeLineConnection": [
      "OrderToFeeLineConnection"
    ],
    "FeeLineConnectionEdge": [
      "OrderToFeeLineConnectionEdge"
    ],
    "GalleryConnection": [
      "RootQueryToGalleryConnection"
    ],
    "GalleryConnectionEdge": [
      "GalleryToPreviewConnectionEdge",
      "RootQueryToGalleryConnectionEdge"
    ],
    "GlobalProductAttributeConnection": [
      "ProductToGlobalProductAttributeConnection"
    ],
    "GlobalProductAttributeConnectionEdge": [
      "ProductToGlobalProductAttributeConnectionEdge"
    ],
    "HierarchicalContentNode": [
      "MediaItem",
      "Page"
    ],
    "HierarchicalNode": [
      "Category",
      "MediaItem",
      "Page",
      "ProductCategory"
    ],
    "HierarchicalTermNode": [
      "Category",
      "ProductCategory"
    ],
    "LineItemConnection": [
      "OrderToLineItemConnection"
    ],
    "LineItemConnectionEdge": [
      "OrderToLineItemConnectionEdge"
    ],
    "LocalProductAttributeConnection": [
      "ProductToLocalProductAttributeConnection"
    ],
    "LocalProductAttributeConnectionEdge": [
      "ProductToLocalProductAttributeConnectionEdge"
    ],
    "MediaItemConnection": [
      "ProductToMediaItemConnection",
      "RootQueryToMediaItemConnection",
      "UserToMediaItemConnection"
    ],
    "MediaItemConnectionEdge": [
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "ProductToMediaItemConnectionEdge",
      "RootQueryToMediaItemConnectionEdge",
      "UserToMediaItemConnectionEdge"
    ],
    "MenuConnection": [
      "RootQueryToMenuConnection"
    ],
    "MenuConnectionEdge": [
      "MenuItemToMenuConnectionEdge",
      "RootQueryToMenuConnectionEdge"
    ],
    "MenuItemConnection": [
      "MenuItemToMenuItemConnection",
      "MenuToMenuItemConnection",
      "RootQueryToMenuItemConnection"
    ],
    "MenuItemConnectionEdge": [
      "MenuItemToMenuItemConnectionEdge",
      "MenuToMenuItemConnectionEdge",
      "RootQueryToMenuItemConnectionEdge"
    ],
    "MenuItemLinkable": [
      "Category",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "ProductCategory",
      "ProductTag",
      "Service",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "VariableProduct"
    ],
    "MenuItemLinkableConnectionEdge": [
      "MenuItemToMenuItemLinkableConnectionEdge"
    ],
    "MenuItemObjectUnion": [
      "Post",
      "Page",
      "Service",
      "Testimonial",
      "Gallery",
      "Category",
      "Tag",
      "ProductCategory",
      "ProductTag"
    ],
    "Node": [
      "CartItem",
      "Category",
      "Comment",
      "CommentAuthor",
      "ContentType",
      "Coupon",
      "CouponLine",
      "Customer",
      "DownloadableItem",
      "EnqueuedScript",
      "EnqueuedStylesheet",
      "ExternalProduct",
      "FeeLine",
      "Gallery",
      "GlobalProductAttribute",
      "GroupProduct",
      "LineItem",
      "LocalProductAttribute",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Order",
      "Page",
      "PaymentGateway",
      "Plugin",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ProductVariation",
      "Refund",
      "Service",
      "ShippingClass",
      "ShippingLine",
      "ShippingMethod",
      "SimpleAttribute",
      "SimpleProduct",
      "Tag",
      "TaxLine",
      "TaxRate",
      "Taxonomy",
      "Testimonial",
      "Theme",
      "User",
      "UserRole",
      "VariableProduct",
      "VariationAttribute",
      "VisibleProduct"
    ],
    "NodeWithAuthor": [
      "MediaItem",
      "Page",
      "Post",
      "Service"
    ],
    "NodeWithComments": [
      "ExternalProduct",
      "GroupProduct",
      "MediaItem",
      "Order",
      "Page",
      "Post",
      "SimpleProduct",
      "VariableProduct"
    ],
    "NodeWithContentEditor": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithExcerpt": [
      "ExternalProduct",
      "GroupProduct",
      "Post",
      "SimpleProduct",
      "VariableProduct"
    ],
    "NodeWithFeaturedImage": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "ProductVariation",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithPageAttributes": [
      "Page"
    ],
    "NodeWithRevisions": [
      "Page",
      "Post",
      "Service"
    ],
    "NodeWithTemplate": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithTitle": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "NodeWithTrackbacks": [
      "Post"
    ],
    "OneToOneConnection": [
      "CartItemToProductConnectionEdge",
      "CartItemToProductVariationConnectionEdge",
      "CategoryToParentCategoryConnectionEdge",
      "CategoryToTaxonomyConnectionEdge",
      "CommentToCommenterConnectionEdge",
      "CommentToContentNodeConnectionEdge",
      "CommentToParentCommentConnectionEdge",
      "ContentNodeToContentTypeConnectionEdge",
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "GalleryToPreviewConnectionEdge",
      "HierarchicalContentNodeToParentContentNodeConnectionEdge",
      "LineItemToProductConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "MenuItemToMenuConnectionEdge",
      "MenuItemToMenuItemLinkableConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "NodeWithFeaturedImageToMediaItemConnectionEdge",
      "NodeWithRevisionsToContentNodeConnectionEdge",
      "PageToPreviewConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "PostToPreviewConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "ProductVariationToVariableProductConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "TestimonialToPreviewConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "OrderConnection": [
      "CustomerToOrderConnection",
      "RootQueryToOrderConnection"
    ],
    "OrderConnectionEdge": [
      "CustomerToOrderConnectionEdge",
      "RootQueryToOrderConnectionEdge"
    ],
    "PageConnection": [
      "PageToRevisionConnection",
      "RootQueryToPageConnection",
      "UserToPageConnection"
    ],
    "PageConnectionEdge": [
      "PageToPreviewConnectionEdge",
      "PageToRevisionConnectionEdge",
      "RootQueryToPageConnectionEdge",
      "UserToPageConnectionEdge"
    ],
    "PaymentGatewayConnection": [
      "RootQueryToPaymentGatewayConnection"
    ],
    "PaymentGatewayConnectionEdge": [
      "RootQueryToPaymentGatewayConnectionEdge"
    ],
    "PluginConnection": [
      "RootQueryToPluginConnection"
    ],
    "PluginConnectionEdge": [
      "RootQueryToPluginConnectionEdge"
    ],
    "PostConnection": [
      "CategoryToPostConnection",
      "PostFormatToPostConnection",
      "PostToRevisionConnection",
      "RootQueryToPostConnection",
      "TagToPostConnection",
      "UserToPostConnection"
    ],
    "PostConnectionEdge": [
      "CategoryToPostConnectionEdge",
      "PostFormatToPostConnectionEdge",
      "PostToPreviewConnectionEdge",
      "PostToRevisionConnectionEdge",
      "RootQueryToPostConnectionEdge",
      "TagToPostConnectionEdge",
      "UserToPostConnectionEdge"
    ],
    "PostFormatConnection": [
      "PostToPostFormatConnection",
      "RootQueryToPostFormatConnection"
    ],
    "PostFormatConnectionEdge": [
      "PostToPostFormatConnectionEdge",
      "RootQueryToPostFormatConnectionEdge"
    ],
    "Previewable": [
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "Page",
      "Post",
      "Service",
      "SimpleProduct",
      "Testimonial",
      "VariableProduct"
    ],
    "Product": [
      "ExternalProduct",
      "GroupProduct",
      "SimpleProduct",
      "VariableProduct"
    ],
    "ProductAttribute": [
      "GlobalProductAttribute",
      "LocalProductAttribute"
    ],
    "ProductAttributeConnection": [
      "ProductToProductAttributeConnection"
    ],
    "ProductAttributeConnectionEdge": [
      "ProductToProductAttributeConnectionEdge"
    ],
    "ProductCategoryConnection": [
      "CouponToExcludedProductCategoriesConnection",
      "CouponToProductCategoryConnection",
      "ProductCategoryToAncestorsProductCategoryConnection",
      "ProductCategoryToProductCategoryConnection",
      "ProductToProductCategoryConnection",
      "RootQueryToProductCategoryConnection"
    ],
    "ProductCategoryConnectionEdge": [
      "CouponToExcludedProductCategoriesConnectionEdge",
      "CouponToProductCategoryConnectionEdge",
      "ProductCategoryToAncestorsProductCategoryConnectionEdge",
      "ProductCategoryToParentProductCategoryConnectionEdge",
      "ProductCategoryToProductCategoryConnectionEdge",
      "ProductToProductCategoryConnectionEdge",
      "RootQueryToProductCategoryConnectionEdge"
    ],
    "ProductConnection": [
      "CouponToExcludedProductsConnection",
      "CouponToProductConnection",
      "GroupProductToProductConnection",
      "ProductCategoryToProductConnection",
      "ProductTagToProductConnection",
      "ProductToProductConnection",
      "ProductToUpsellConnection",
      "ProductTypeToProductConnection",
      "RootQueryToProductConnection",
      "ShippingClassToProductConnection",
      "SimpleProductToProductConnection",
      "VariableProductToProductConnection",
      "VisibleProductToProductConnection"
    ],
    "ProductConnectionEdge": [
      "CartItemToProductConnectionEdge",
      "CouponToExcludedProductsConnectionEdge",
      "CouponToProductConnectionEdge",
      "GroupProductToProductConnectionEdge",
      "LineItemToProductConnectionEdge",
      "ProductCategoryToProductConnectionEdge",
      "ProductTagToProductConnectionEdge",
      "ProductToPreviewConnectionEdge",
      "ProductToProductConnectionEdge",
      "ProductToUpsellConnectionEdge",
      "ProductTypeToProductConnectionEdge",
      "RootQueryToProductConnectionEdge",
      "ShippingClassToProductConnectionEdge",
      "SimpleProductToProductConnectionEdge",
      "VariableProductToProductConnectionEdge",
      "VisibleProductToProductConnectionEdge"
    ],
    "ProductTagConnection": [
      "ProductToProductTagConnection",
      "RootQueryToProductTagConnection"
    ],
    "ProductTagConnectionEdge": [
      "ProductToProductTagConnectionEdge",
      "RootQueryToProductTagConnectionEdge"
    ],
    "ProductTypeConnection": [
      "ProductToProductTypeConnection",
      "RootQueryToProductTypeConnection"
    ],
    "ProductTypeConnectionEdge": [
      "ProductToProductTypeConnectionEdge",
      "RootQueryToProductTypeConnectionEdge"
    ],
    "ProductVariationConnection": [
      "VariableProductToProductVariationConnection"
    ],
    "ProductVariationConnectionEdge": [
      "CartItemToProductVariationConnectionEdge",
      "LineItemToProductVariationConnectionEdge",
      "VariableProductToProductVariationConnectionEdge"
    ],
    "RefundConnection": [
      "CustomerToRefundConnection",
      "OrderToRefundConnection",
      "RootQueryToRefundConnection"
    ],
    "RefundConnectionEdge": [
      "CustomerToRefundConnectionEdge",
      "OrderToRefundConnectionEdge",
      "RootQueryToRefundConnectionEdge"
    ],
    "ServiceConnection": [
      "RootQueryToServiceConnection",
      "ServiceToRevisionConnection",
      "UserToServiceConnection"
    ],
    "ServiceConnectionEdge": [
      "RootQueryToServiceConnectionEdge",
      "ServiceToPreviewConnectionEdge",
      "ServiceToRevisionConnectionEdge",
      "UserToServiceConnectionEdge"
    ],
    "ShippingClassConnection": [
      "ProductToShippingClassConnection",
      "ProductVariationToShippingClassConnection",
      "RootQueryToShippingClassConnection"
    ],
    "ShippingClassConnectionEdge": [
      "ProductToShippingClassConnectionEdge",
      "ProductVariationToShippingClassConnectionEdge",
      "RootQueryToShippingClassConnectionEdge"
    ],
    "ShippingLineConnection": [
      "OrderToShippingLineConnection"
    ],
    "ShippingLineConnectionEdge": [
      "OrderToShippingLineConnectionEdge"
    ],
    "ShippingMethodConnection": [
      "RootQueryToShippingMethodConnection"
    ],
    "ShippingMethodConnectionEdge": [
      "RootQueryToShippingMethodConnectionEdge"
    ],
    "TagConnection": [
      "PostToTagConnection",
      "RootQueryToTagConnection"
    ],
    "TagConnectionEdge": [
      "PostToTagConnectionEdge",
      "RootQueryToTagConnectionEdge"
    ],
    "TaxLineConnection": [
      "OrderToTaxLineConnection"
    ],
    "TaxLineConnectionEdge": [
      "OrderToTaxLineConnectionEdge"
    ],
    "TaxRateConnection": [
      "RootQueryToTaxRateConnection"
    ],
    "TaxRateConnectionEdge": [
      "RootQueryToTaxRateConnectionEdge"
    ],
    "TaxonomyConnection": [
      "ContentTypeToTaxonomyConnection",
      "RootQueryToTaxonomyConnection"
    ],
    "TaxonomyConnectionEdge": [
      "CategoryToTaxonomyConnectionEdge",
      "ContentTypeToTaxonomyConnectionEdge",
      "PostFormatToTaxonomyConnectionEdge",
      "ProductCategoryToTaxonomyConnectionEdge",
      "ProductTagToTaxonomyConnectionEdge",
      "ProductTypeToTaxonomyConnectionEdge",
      "RootQueryToTaxonomyConnectionEdge",
      "ShippingClassToTaxonomyConnectionEdge",
      "TagToTaxonomyConnectionEdge",
      "VisibleProductToTaxonomyConnectionEdge"
    ],
    "TermNode": [
      "Category",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ShippingClass",
      "Tag",
      "VisibleProduct"
    ],
    "TermNodeConnection": [
      "GlobalProductAttributeToTermNodeConnection",
      "PostToTermNodeConnection",
      "ProductToTermNodeConnection",
      "RootQueryToTermNodeConnection"
    ],
    "TermNodeConnectionEdge": [
      "GlobalProductAttributeToTermNodeConnectionEdge",
      "PostToTermNodeConnectionEdge",
      "ProductToTermNodeConnectionEdge",
      "RootQueryToTermNodeConnectionEdge"
    ],
    "TestimonialConnection": [
      "RootQueryToTestimonialConnection"
    ],
    "TestimonialConnectionEdge": [
      "RootQueryToTestimonialConnectionEdge",
      "TestimonialToPreviewConnectionEdge"
    ],
    "ThemeConnection": [
      "RootQueryToThemeConnection"
    ],
    "ThemeConnectionEdge": [
      "RootQueryToThemeConnectionEdge"
    ],
    "UniformResourceIdentifiable": [
      "Category",
      "ContentType",
      "ExternalProduct",
      "Gallery",
      "GroupProduct",
      "MediaItem",
      "Page",
      "Post",
      "PostFormat",
      "ProductCategory",
      "ProductTag",
      "ProductType",
      "ProductVariation",
      "Service",
      "ShippingClass",
      "SimpleProduct",
      "Tag",
      "Testimonial",
      "User",
      "VariableProduct",
      "VisibleProduct"
    ],
    "UserConnection": [
      "RootQueryToUserConnection"
    ],
    "UserConnectionEdge": [
      "ContentNodeToEditLastConnectionEdge",
      "ContentNodeToEditLockConnectionEdge",
      "NodeWithAuthorToUserConnectionEdge",
      "RootQueryToUserConnectionEdge"
    ],
    "UserRoleConnection": [
      "RootQueryToUserRoleConnection",
      "UserToUserRoleConnection"
    ],
    "UserRoleConnectionEdge": [
      "RootQueryToUserRoleConnectionEdge",
      "UserToUserRoleConnectionEdge"
    ],
    "VariableProductConnectionEdge": [
      "ProductVariationToVariableProductConnectionEdge"
    ],
    "VariationAttributeConnection": [
      "ExternalProductToVariationAttributeConnection",
      "GroupProductToVariationAttributeConnection",
      "ProductVariationToVariationAttributeConnection",
      "SimpleProductToVariationAttributeConnection",
      "VariableProductToVariationAttributeConnection"
    ],
    "VariationAttributeConnectionEdge": [
      "ExternalProductToVariationAttributeConnectionEdge",
      "GroupProductToVariationAttributeConnectionEdge",
      "ProductVariationToVariationAttributeConnectionEdge",
      "SimpleProductToVariationAttributeConnectionEdge",
      "VariableProductToVariationAttributeConnectionEdge"
    ],
    "VisibleProductConnection": [
      "ProductToVisibleProductConnection",
      "ProductVariationToVisibleProductConnection",
      "RootQueryToVisibleProductConnection"
    ],
    "VisibleProductConnectionEdge": [
      "ProductToVisibleProductConnectionEdge",
      "ProductVariationToVisibleProductConnectionEdge",
      "RootQueryToVisibleProductConnectionEdge"
    ]
  }
};
      export default result;
    