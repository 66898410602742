import React from "react";
import { UseErrorHandler } from "lib/hooks/useErrorHandler";
import { ApolloError } from "@apollo/client";
import { Alert } from "../Alert/Alert";
import { AlertProps } from "@material-ui/lab/Alert";

interface Props {
  onClose?: () => void;
  error?: string | UseErrorHandler | ApolloError;
  errorText?: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  alertProps?: AlertProps;
  className?: string;
}

export const ErrorMessage = (props: Props) => {
  const handleOnClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  if (!props.error) {
    return <>{props.children}</>;
  }

  return (
    <Alert
      severity="error"
      className={props.className}
      onClose={props.showCloseButton ? handleOnClose : undefined}
      {...props.alertProps}
    >
      {props.errorText || props.error.toString()}
    </Alert>
  );
};
