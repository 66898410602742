import React, { CSSProperties } from "react";
import placeholder from "assets/images/placeholder.jpg";

interface Props {
  src?: string | null;
  alt?: string;
  style?: CSSProperties;
}

const Image = (props: Props) => {
  return (
    <img
      src={props.src || placeholder}
      alt={props.alt || ""}
      style={{ ...props.style }}
    />
  );
};

export default Image;
