import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Page } from 'components/shared/Page/Page';
import { useGetCartQuery } from 'graphql/generated/generated';
import { useCheckoutFormik } from 'lib/hooks/formik/useCheckoutFormik';
import AddressForm from 'components/checkout/AddressForm';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { CheckoutCard } from 'components/checkout/CheckoutCard';
import Button from 'components/shared/Button/Button';

interface Props {}

const useStyles = makeStyles((theme) =>
  createStyles({
    product: {
      width: '100%',
      height: '100%',
    },
    checkoutButton: {
      width: '100%',
      marginTop: '20px',
    },
  })
);

export const Checkout = (props: RouteComponentProps<Props>) => {
  const classes = useStyles();
  const cart = useGetCartQuery();
  const [submitting, setSubmitting] = useState(false);

  const formik = useCheckoutFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    } as any,
    onSubmit: async (values, helpers) => {
      try {
        setSubmitting(true);

        // const billingAndShipping = {
        //   firstName: values.firstName,
        //   lastName: values.lastName,
        //   address1: values.address,
        //   state: values.country,
        //   city: values.city,
        //   postcode: values.zip,
        // };

        // const checkoutRes = await checkout({
        //   variables: {
        //     inputData: {
        //       clientMutationId: 'checkout-maluki',
        //       paymentMethod: 'ecc',
        //       shippingMethod: ['free_shipping:1'],
        //       billing: {
        //         ...billingAndShipping,
        //         email: values.email,
        //       },
        //       shipping: {
        //         ...billingAndShipping,
        //       },
        //     },
        //   },
        // });

        // const redirect = checkoutRes.data?.checkout?.redirect;
        // if (redirect) {
        //   const redirectObject = JSON.parse(redirect);
        //   const form = document.createElement('form');
        //   form.setAttribute('method', 'post');
        //   form.setAttribute('action', redirectObject.actionUrl);

        //   Object.keys(redirectObject).forEach((k: any) => {
        //     const val = (redirectObject as any)[k];
        //     if (k === 'actionUrl') {
        //       return;
        //     }
        //     form.appendChild(createInput(k, val));
        //   });

        //   form.style.display = 'none';
        //   document.body.appendChild(form);
        //   form.submit();
        // }
      } catch (e) {
        setSubmitting(false);
      }
    },
  });

  return (
    <Page animate withContainer>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AddressForm formik={formik} />
        </Grid>
        <Grid item xs={6}>
          <CheckoutCard card={cart} />
          <Button
            color="primary"
            className={classes.checkoutButton}
            disabled={submitting}
            onClick={() => formik.handleSubmit()}
          >
            {submitting ? 'Loading...' : 'Checkout'}
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};
