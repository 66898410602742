import React from 'react';
import { Typography } from '@material-ui/core';
import { useContactFormik } from 'lib/hooks/formik/useContactFormik';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';
import { useErrorHandler } from 'lib/hooks/useErrorHandler';
import { TextField } from 'components/shared/TextField/TextField';
import Button from 'components/shared/Button/Button';
// import { useSnackbar } from '../../../lib/context/SnackbarContext/SnackbarContext';
import { useFormStyles } from './useFormStyles';

export const ContactForm = () => {
  const classes = useFormStyles();
  const errorHandler = useErrorHandler();
  // const snackbar = useSnackbar();
  // const [login] = useContactMutation();

  const formik = useContactFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      message: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        errorHandler.reset();

        // const res = await login({
        //   variables: {
        //     fullName: values.fullName || "",
        //     email: values.email || "",
        //     phone: values.phone || "",
        //     message: values.message || "",
        //   },
        // });

        // if (!user?.userOptions?.accountActivated) {
        //   snackbar.error('Ju lutem verifikoni llogarine tuaj.');
        //   return;
        // }

        formikHelpers.resetForm();
      } catch (e) {
        errorHandler.handleError(e as any);
        formikHelpers.setErrors({
          fullName: ' ',
          email: ' ',
          phone: ' ',
          message: ' ',
        });
      }
    },
  });

  const { errors, touched } = formik;

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">Kyçu</Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <ErrorMessage
          showCloseButton
          error={errorHandler.error}
          errorText="Email ose fjalekalimi jane gabim!"
          alertProps={{ style: { marginBottom: 15, width: '100%' } }}
          onClose={() => {
            errorHandler.reset();
            formik.setErrors({});
          }}
        />

        <TextField
          fullWidth
          type="text"
          name="fullName"
          id="fullName"
          label="Full Name"
          error={touched.fullName && !!errors.fullName}
          helperText={touched.fullName ? errors.fullName : undefined}
          value={formik.values.fullName || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <TextField
          fullWidth
          type="email"
          name="email"
          id="email"
          label="Email"
          error={touched.email && !!errors.email}
          helperText={touched.email ? errors.email : undefined}
          value={formik.values.email || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <TextField
          fullWidth
          type="text"
          name="phone"
          id="phone"
          label="Phone"
          error={touched.phone && !!errors.phone}
          helperText={touched.phone ? errors.phone : undefined}
          value={formik.values.phone || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <TextField
          fullWidth
          type="textarea"
          name="message"
          id="message"
          label="Message"
          error={touched.message && !!errors.message}
          helperText={touched.message ? errors.message : undefined}
          value={formik.values.message || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div className={classes.formActions}>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            color="primary"
            style={{ marginTop: 25 }}
          >
            {formik.isSubmitting ? 'Please wait' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
};
