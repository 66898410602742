import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Page } from 'components/shared/Page/Page';
import { Product as ProductComponent } from 'components/product/Product';

interface Props {
  slug: string;
}

export const Product = (props: RouteComponentProps<Props>) => {
  return (
    <Page animate withContainer>
      <ProductComponent slug={props.match.params.slug} />
    </Page>
  );
};
