import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { DefaultPage } from "pages/DefaultPage/DefaultPage";
import { Page } from "components/shared/Page/Page";
import { useGetPageBySlugQuery } from "../../graphql/generated/generated";

interface Props {}

export const PrivacyPolicy: React.FC<RouteComponentProps<Props>> = (props) => {
  const query = useGetPageBySlugQuery({
    variables: {
      slug: "privacy-policy",
    },
  });

  return (
    <Page animate withContainer>
      <DefaultPage
        loading={query.loading}
        title={query.data?.pageBy?.title}
        content={query.data?.pageBy?.content}
      />
    </Page>
  );
};
