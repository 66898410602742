import React from 'react';
import { QueryResult } from '@apollo/client';
import { Exact, GetCartQuery } from 'graphql/generated/generated';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import Image from 'components/shared/Image/Image';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';
import { Skeleton } from '@material-ui/lab';

interface Props {
  card: QueryResult<
    GetCartQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    checkoutCard: {
      width: '100%',
    },
    checkoutCardList: {
      width: '100%',
    },
    cardItem: {
      width: '100%',
      display: 'flex',
    },
    cardItemImage: {
      width: '170px',
      height: '100px',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    cardItemContent: {
      width: 'calc(100% - 190px)',
      paddingLeft: '20px',
    },
  })
);

export const CheckoutCard = (props: Props) => {
  const classes = useStyles();
  const { data, loading, error } = props.card;

  return (
    <div className={classes.checkoutCard}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Card
      </Typography>
      {error && <ErrorMessage error={error} />}
      {loading && (
        <div className={classes.cardItem}>
          <div className={classes.cardItemImage}>
            <Skeleton width="100%" height={100} style={{ transform: 'none' }} />
          </div>
          <div className={classes.cardItemContent}>
            <Skeleton
              width="100%"
              height={20}
              style={{ transform: 'none', marginBottom: '10px' }}
            />
            <Skeleton
              width="100%"
              height={20}
              style={{ transform: 'none', marginBottom: '10px' }}
            />
            <Skeleton
              width="100%"
              height={20}
              style={{ transform: 'none', marginBottom: '10px' }}
            />
          </div>
        </div>
      )}
      <div className={classes.checkoutCardList}>
        {data?.cart?.contents?.nodes.map((item) => {
          return (
            <div key={item.key} className={classes.cardItem}>
              <div className={classes.cardItemImage}>
                <Image src={item.product?.node.image?.sourceUrl} />
              </div>
              <div className={classes.cardItemContent}>
                <Typography>{item.product?.node.name}</Typography>
                <Typography>Quantity: {item.quantity}</Typography>
                <Typography>
                  Price: {item.variation?.node.regularPrice}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
