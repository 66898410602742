import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { HtmlContent } from 'components/shared/HTMLContent/HTMLContent';
import { Skeleton } from '@material-ui/lab';
import { Maybe } from 'graphql/jsutils/Maybe';

const useStyles = makeStyles({
  defaultPage: {
    '& .HTMLContent p ': {
      marginBottom: 10,
      lineHeight: '1.2em',
    },
    '& .HTMLContent': {
      '& h1, h2, h3, h4, h5, h6': {
        marginBottom: 10,
      },
    },
  },
});

interface Props {
  loading?: boolean;
  title?: Maybe<string>;
  image?: Maybe<string>;
  content?: Maybe<string>;
}

export const DefaultPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.defaultPage}>
      <Typography
        style={{ marginBottom: 30, textAlign: 'center' }}
        variant="h5"
      >
        {props.loading ? (
          <Skeleton width="100%" height={500} style={{ transform: 'none' }} />
        ) : (
          <>
            {props?.image && (
              <img src={`${props?.image}`} alt={`${props?.title}`} />
            )}
          </>
        )}
      </Typography>
      <Typography
        style={{ marginBottom: 30, textAlign: 'center' }}
        variant="h5"
      >
        {props.loading ? (
          <Skeleton
            height={50}
            style={{ maxWidth: 200, margin: '0 auto', transform: 'none' }}
          />
        ) : (
          props.title
        )}
      </Typography>

      {props.loading ? (
        <>
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
          <Skeleton width="100%" height={20} style={{ transform: 'none' }} />
        </>
      ) : (
        <HtmlContent content={props.content} />
      )}
    </div>
  );
};
