import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/shared/Button/Button";
import { ArrowBack } from "@material-ui/icons";
import { useRouter } from "lib/hooks/useRouter";
import { Animate } from "components/shared/Animate/Animate";
import Container from "../Container/Container";

const useStyles = makeStyles({
  page: {
    width: "100%",
    minHeight: "calc(100vh - 330px)",
    padding: "20px 0 20px 0",
  },
  backButton: {
    marginBottom: 20,
  },
});

interface Props {
  className?: string | string[];
  children: React.ReactNode;
  animate?: boolean;
  showBackButton?: boolean;
  backButtonText?: string;
  onGoBack?: () => void;
  withContainer?: boolean;
}
export const Page = (props: Props) => {
  const classes = useStyles();
  const router = useRouter();

  const handleOnGoBackClick = () => {
    if (props.onGoBack) {
      props.onGoBack();
      return;
    }

    router.history.goBack();
  };

  const Wrapper = props.withContainer ? Container : React.Fragment;

  return (
    <Wrapper>
      <Animate animate={props.animate ? "bottom-up" : undefined}>
        <div className={classNames([classes.page, props.className])}>
          {props.showBackButton && (
            <div className={classes.backButton}>
              <Button
							  title={props.backButtonText || 'mbrapa'}
                color="inherit"
                onClick={handleOnGoBackClick}
                style={{ textTransform: "lowercase" }}
              >
								<ArrowBack />
              </Button>
            </div>
          )}
          {props.children}
        </div>
      </Animate>
    </Wrapper>
  );
};
