import React from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import "./HTMLContent.scss";

interface Props {
  content?: Maybe<string> | undefined;
}

export const HtmlContent: React.FC<Props> = (props) => {
  return (
    <span
      className={"HTMLContent"}
      dangerouslySetInnerHTML={{ __html: props.content || "" }}
    />
  );
};
