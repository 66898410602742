import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DefaultPage } from 'pages/DefaultPage/DefaultPage';
import { Page } from 'components/shared/Page/Page';
import { useGetPageBySlugQuery } from '../../graphql/generated/generated';
import { ContactForm } from 'components/shared/ContactForm/ContactForm';

interface Props {}

export const Contact: React.FC<RouteComponentProps<Props>> = (props) => {
  const query = useGetPageBySlugQuery({
    variables: {
      slug: 'contact',
    },
  });

  return (
    <Page animate withContainer>
      <DefaultPage
        loading={query.loading}
        title={query.data?.pageBy?.title}
        content={query.data?.pageBy?.content}
      />
      <ContactForm />
    </Page>
  );
};
