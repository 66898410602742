import { ApolloClient, ApolloLink } from '@apollo/client';
import { cache } from './cache';
import { afterwareLink } from './links/afterwareLink';
import { errorLink } from './links/errorLink';
import { httpLink } from './links/httpLink';
import { middlewareLink } from './links/middlewareLink';

export const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([middlewareLink, afterwareLink, errorLink, httpLink]),
});
