import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface ContactFields {
  fullName: string | null;
  email: string | null;
  phone: string | null;
  message: string | null;
}

interface UseContactFormOptions {
  initialValues?: ContactFields;
  onSubmit: (
    values: ContactFields,
    formikHelpers: FormikHelpers<ContactFields>
  ) => Promise<any>;
}

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const ContactSchema = Yup.object().shape({
  fullName: Yup.string().required('This field is required!'),
  email: Yup.string()
    .email('Please enter a valid email!')
    .required('This field is required!'),
  phone: Yup.string().matches(phoneRegex, 'Please enter a valid phone number!'),
  message: Yup.string().required('This field is required!'),
});

export const useContactFormik = (options: UseContactFormOptions) => {
  return useFormik<ContactFields>({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      message: '',
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ContactSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ContactFormik = ReturnType<typeof useContactFormik>;
