import React, { CSSProperties } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
    color: '#323232',
    fontSize: 24,
  },
  link: {
    letterSpacing: 1,
    color: '#000',
    fontSize: 15,
  },
});

interface Props {
  text: string;
  rightLinkText?: string;
  rightLinkTo?: string;
  style?: CSSProperties;
  parentStyle?: CSSProperties;
}

export const SectionTitle: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div style={{ ...props.parentStyle }}>
      <Typography className={classes.title} style={{ ...props.style }}>
        {props.text}
      </Typography>
    </div>
  );
};
