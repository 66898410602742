import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useFormStyles = makeStyles((theme) =>
  createStyles({
    formWrapper: {
      width: "100%",
      maxWidth: 600,
      padding: 30,
      borderRadius: 6,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      border: "1px solid #D7D7D7",
      backgroundColor: "#E6EBE5",
      "& .MuiTypography-h5": {
        textAlign: "center",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        background: "#fff",
      },
    },

    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 30,
      "& input": {
        backgroundColor: "#fff",
      },
    },

    formActions: {
      width: "100%",
      marginTop: 25,
      "& a": {
        textDecoration: "none",
      },
    },
  })
);
