import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  useAddProductToCartMutation,
  useGetProductBySlugQuery,
} from 'graphql/generated/generated';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';
import Image from 'components/shared/Image/Image';
import { Skeleton } from '@material-ui/lab';
import { HtmlContent } from 'components/shared/HTMLContent/HTMLContent';
import { ProductPrices } from './ProductPrices';
import { ProductVariations } from './ProductVariations';
import Button from 'components/shared/Button/Button';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) =>
  createStyles({
    product: {
      width: '100%',
      height: '100%',
    },
    productButton: {
      width: '100%',
      marginTop: '20px',
    },
  })
);

interface Props {
  slug: string;
}

export const Product = (props: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedVariation, setSelectedVariation] = useState<any>(null);
  const { data, loading, error } = useGetProductBySlugQuery({
    variables: {
      slugIn: [props.slug],
    },
  });
  const [
    addToCart,
    { error: cartError, loading: cartLoading },
  ] = useAddProductToCartMutation({
    onCompleted: () => {
      history.push('/checkout');
    },
  });

  const onVariationChange = (variation: any) => {
    setSelectedVariation(variation);
  };

  const handleSubmit = () => {
    if (!product || !selectedVariation) return;

    addToCart({
      variables: {
        clientMutationId: `add-to-card-${Math.random() * 100}`,
        productId: product.databaseId,
        quantity: 1,
        variationId: selectedVariation.databaseId as number,
      },
    });
  };

  const getPrices = () => {
    if (!product) return;
    if (selectedVariation) {
      return {
        regularPrice: selectedVariation.regularPrice,
        salePrice: selectedVariation.salePrice,
      };
    }
    return {
      //@ts-ignore
      regularPrice: product.regularPrice,
      //@ts-ignore
      salePrice: product.salePrice,
    };
  };

  if (error) return <ErrorMessage error={error} />;

  const product = data?.products?.nodes[0];
  const prices = getPrices();

  return (
    <div className={classes.product}>
      <Grid container style={{ display: 'flex' }} alignItems="flex-start">
        <Grid item style={{ width: '750px', marginBottom: 20 }}>
          {loading ? (
            <Skeleton width="100%" height={500} style={{ transform: 'none' }} />
          ) : (
            <Image src={product?.image?.sourceUrl} />
          )}
        </Grid>
        <Grid item style={{ width: 'calc(100% - 750px', paddingLeft: 30 }}>
          <Typography variant="h4" style={{ marginBottom: 15 }}>
            {product?.name}
          </Typography>
          <Grid
            style={{ fontSize: '16px', lineHeight: '1.4em', marginBottom: 20 }}
          >
            <HtmlContent content={product?.description} />
          </Grid>

          <ProductVariations
            // @ts-ignore
            variations={product?.variations}
            active={selectedVariation?.databaseId}
            onChange={onVariationChange}
          />

          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 30,
              paddingTop: '20px',
              borderTop: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <Typography variant="h5" style={{ marginRight: 10 }}>
              Price:
            </Typography>
            <ProductPrices
              variant="h5"
              regular={prices?.regularPrice}
              sale={prices?.salePrice}
            />
          </Grid>

          <Button
            color="primary"
            className={classes.productButton}
            disabled={cartLoading}
            onClick={handleSubmit}
          >
            {cartLoading ? 'Loading...' : 'Buy now'}
          </Button>
          {cartError && <ErrorMessage error={cartError} />}
        </Grid>
      </Grid>
    </div>
  );
};
