export type LocalStorageKeys = 'wooSession';

export interface LocalStorageValues {
  authToken: string;
  refreshToken: string;
  wooSession: string;
  wishlist?: Array<number>;
}

export const parseLocalStorageValues = () => {
  const localItems = localStorage.getItem('KAILY_GASHI_USER');

  if (localItems === null) {
    return {};
  }

  return JSON.parse(localItems);
};

export const getLocalValues = () => {
  const localValues = parseLocalStorageValues();

  return {
    authToken: localValues?.authToken,
    refreshToken: localValues?.refreshToken,
    wooSession: localValues?.wooSession,
    wishlist: localValues?.wishlist || [],
    welcomeModal: localValues?.welcomeModal || '',
  };
};

export const setLocalValue = (
  key: LocalStorageKeys,
  value: string | number[]
) => {
  const localValues = parseLocalStorageValues();

  const newValue = {
    ...localValues,
    [key]: value,
  };

  localStorage.setItem('KAILY_GASHI_USER', JSON.stringify(newValue));
};

export const deleteLocalValue = (key: LocalStorageKeys) => {
  const localValues = parseLocalStorageValues();

  if (localValues[key]) {
    delete localValues[key];
  }

  if (localValues) {
    localStorage.setItem('KAILY_GASHI_USER', JSON.stringify(localValues));
  } else {
    localStorage.removeItem('KAILY_GASHI_USER');
  }
};
