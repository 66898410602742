import React from 'react';
import { Page } from 'components/shared/Page/Page';
import { ProductsList } from 'components/product/ProductsList';
import { Grid } from '@material-ui/core';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';

export const LifestylePhotography = () => {
  return (
    <Page animate withContainer>
      <Grid item style={{ width: '100%', padding: 0, marginBottom: 10 }}>
        <SectionTitle
          text="Lifestyle Photography"
          style={{ textAlign: 'center' }}
        />
      </Grid>
      <ProductsList />
    </Page>
  );
};
