import React from 'react';
import { Redirect, RouteProps, Switch, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { RestrictedRoute } from './RestrictedRoute';

import { Page404 } from 'pages/Page404/Page404';
import { Home } from 'pages/Home/Home';
import { Product } from 'pages/Product/Product';
import { LifestylePhotography } from 'pages/LifestylePhotography/LifestylePhotography';
import { BrandPhotography } from 'pages/BrandPhotography/BrandPhotography';
import { About } from 'pages/About/About';
import { Contact } from 'pages/Contact/Contact';
import { Gallery } from 'pages/Gallery/Gallery';
import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';
import { TermsAndConditions } from 'pages/TermsAndConditions/TermsAndConditions';
import { Checkout } from 'pages/Checkout/Checkout';

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

interface AppRoute extends RouteProps {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: '/',
    component: Home,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'lifestyle-photography',
    component: LifestylePhotography,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'brand-photography',
    component: BrandPhotography,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'service/:slug',
    component: Product,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'checkout',
    component: Checkout,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'gallery',
    component: Gallery,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'about',
    component: About,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'contact',
    component: Contact,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'terms-and-conditions',
    component: TermsAndConditions,
  },
];

export const Routes = () => {
  const location = useLocation();

  const queryParams = qs.parse(location.search);

  const redirect = queryParams.redirect;

  if (redirect) {
    return <Redirect to={redirect.toString()} />;
  }

  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;

        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }

        if (type === RouteType.RESTRICTED) {
          return (
            <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
      })}

      <PublicRoute component={Page404} />
    </Switch>
  );
};
