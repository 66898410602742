import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Skeleton from '@material-ui/lab/Skeleton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import placeholder from 'assets/images/placeholder.jpg';
import { useRouter } from 'lib/hooks/useRouter';

interface ProductCategoryProps {
  loading?: boolean;
  item: any;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      padding: 0,
      // background: theme.palette.secondary.main,
      boxShadow: 'none',
      textAlign: 'center',
      '& :hover .MuiCardMedia-root': {
        transform: 'scale(1.1)',
      },
    },
    rootBtn: {
      '& .MuiCardActionArea-focusHighlight': {
        opacity: '0!important',
      },
    },

    mediaWrapper: {
      width: '100%',
      height: 300,
      overflow: 'hidden',
      borderRadius: 5,
    },

    media: {
      height: '100%',
      transition: 'all 0.3s ease-in-out',
    },
  });
});

export const ProductCategory = (props: ProductCategoryProps) => {
  const { item } = props;
  const classes = useStyles();
  const router = useRouter();

  return (
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Card component="div" className={classes.root}>
        <CardActionArea
          className={classes.rootBtn}
          onClick={() => router.history.push(`${item.link.url}`)}
        >
          {item ? (
            <div className={classes.mediaWrapper}>
              <CardMedia
                className={classes.media}
                image={item.image?.sourceUrl || placeholder}
                title=""
              />
            </div>
          ) : (
            <Skeleton variant="rect" height={200} />
          )}
          <CardContent>
            {item ? (
              <Typography variant="h6">
                <strong>{item.title}</strong>
              </Typography>
            ) : (
              <Skeleton height={30} />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
