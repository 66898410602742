import { Typography } from '@material-ui/core';
import React from 'react';
import { removeHTMLEntities } from '../../lib/helpers/removeHTMLEntities';

interface Props {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  sale?: string | null;
  regular?: string;
}

export const ProductPrices = (props: Props) => {
  const classes = props.sale ? 'ProductPrices sale' : 'ProductPrices';
  return (
    <div className={classes}>
      {props.regular && (
        <Typography variant={props.variant || 'h6'}>
          {removeHTMLEntities(props.regular)}
        </Typography>
      )}
      {props.sale && (
        <Typography variant={props.variant || 'h6'} className="sale">
          {removeHTMLEntities(props.sale)}
        </Typography>
      )}
    </div>
  );
};
