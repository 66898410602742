import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  List,
  Hidden,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Facebook, YouTube, Instagram } from '@material-ui/icons';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Tiktok } from 'assets/icons/tiktok.svg';
import MobileDrawer from 'components/partials/Header/ui/MobileDrawer';
import Container from 'components/shared/Container/Container';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: '#fff',
      padding: 0,
      boxShadow: '0px 0px 15px rgba(0,0,0,.08)',
    },
    headeLeft: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headeRight: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      width: 140,
      height: 50,
      marginTop: 5,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    navList: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: 50,
    },
    navLink: {
      fontSize: 14,
      fontWeight: 500,
      color: '#323232',
      marginRight: 25,
    },
    navLinkActive: {
      '& .MuiButtonBase-root': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
      },
    },
    socialMedia: {
      display: 'flex',
      '& li': {
        marginLeft: 5,
        borderRadius: '50%',
        padding: 5,
        '& a': {
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        },

        '&:hover': {
          '& svg': {
            color: theme.palette.secondary.dark,
          },
        },

        '&:first-child': {
          marginLeft: 0,
        },
      },

      '& svg': {
        width: '20px',
        height: '20px',
        color: theme.palette.primary.main,
      },
    },
  })
);

const navLinks = [
  { title: 'Home', path: '/', exact: true },
  {
    title: 'Lifestyle Photography',
    path: '/lifestyle-photography',
    exact: true,
  },
  { title: 'Brand Photography', path: '/brand-photography', exact: true },
  { title: 'Gallery', path: '/gallery' },
  { title: 'About', path: '/about' },
  { title: 'Contact', path: '/contact' },
];

const socialMediaLinks = {
  facebook: '',
  instagram: '',
  youtube: '',
  tiktok: '',
};

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default" className={classes.header}>
      <Toolbar disableGutters>
        <Container className={classes.container}>
          <div className={classes.headeLeft}>
            <Link to="/">
              <Logo className={classes.logo} />
            </Link>

            <Hidden smDown>
              <List
                component="nav"
                aria-labelledby="main navigation"
                className={classes.navList}
              >
                {navLinks.map((item) => {
                  return (
                    <NavLink
                      exact={item.exact}
                      to={item.path}
                      key={item.title}
                      className={classes.navLink}
                      activeClassName={classes.navLinkActive}
                    >
                      {item.title}
                    </NavLink>
                  );
                })}
              </List>
            </Hidden>
          </div>
          <div className={classes.headeRight}>
            <ul className={classes.socialMedia}>
              <li>
                <a href={socialMediaLinks?.instagram || ''}>
                  <Instagram />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.tiktok || ''}>
                  <Tiktok width={18} height={18} />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.facebook || ''}>
                  <Facebook />
                </a>
              </li>
              <li>
                <a href={socialMediaLinks?.youtube || ''}>
                  <YouTube />
                </a>
              </li>
            </ul>
          </div>

          <Hidden mdUp>
            <MobileDrawer navLinks={navLinks} />
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
