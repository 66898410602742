import React from 'react';
import { Page } from 'components/shared/Page/Page';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/shared/Button/Button';
import { Home } from '@material-ui/icons';
import { ReactComponent as NotFound } from '../../assets/images/404-error.svg';

const useStyles = makeStyles(() => ({
  notfound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '100px 0',
  },
  text: {
    fontSize: 20,
    marginBottom: 15,
    fontWeight: 600,
  },
  wrapper: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const Page404 = () => {
  const classes = useStyles();
  return (
    <Page animate withContainer>
      <div className={classes.notfound}>
        <NotFound style={{ width: 150 }} />

        <div className={classes.wrapper}>
          <h1 className={classes.text}>
            The page you are searching was not found!
          </h1>
          <Button variant="outlined" to="/" startIcon={<Home />}>
            Back Home
          </Button>
        </div>
      </div>
    </Page>
  );
};
