import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { getLocalValues } from 'lib/helpers/localStorageHelpers';

interface RequestHeadersType {
  'woocommerce-session'?: string;
}

export const attachRequestHeaders = () => {
  let requestHeaders: RequestHeadersType = {};
  const { wooSession } = getLocalValues();

  if (wooSession) {
    requestHeaders['woocommerce-session'] = `Session ${wooSession}`;
  }

  return requestHeaders;
};

export const middlewareLink = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    const requestHeaders = attachRequestHeaders();

    if (Object.entries(requestHeaders).length) {
      operation.setContext(() => ({
        headers: {
          ...requestHeaders,
        },
      }));
    }

    return forward(operation);
  }
);
