import React from "react";
import { Container as MUIContainer, ContainerProps } from "@material-ui/core";

interface Props extends ContainerProps {}

const Container = (props: Props) => {
  return <MUIContainer {...props}>{props.children}</MUIContainer>;
};

export default Container;
