import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { ProductPrices } from './ProductPrices';

interface Props {
  active?: number;
  variations?: any;
  onChange?: (variation: any) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    productVariations: {
      width: '100%',
    },
    productVariation: {
      width: '100%',
      border: '1px solid rgba(0,0,0,0.1)',
      borderRadius: '3px',
      padding: '20px 30px',
      marginBottom: '10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    productVariationValues: {
      width: 'calc(100% - 60px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    productVariationRadio: {
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(0,0,0,0.3)',
      borderRadius: '100%',
      marginRight: '30px',

      '& span': {
        width: '20px',
        height: '20px',
        backgroundColor: '#000',
        borderRadius: '100%',
      },
    },
  })
);

export const ProductVariations = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.productVariations}>
      {props?.variations?.nodes.map((variation: any) => {
        const isActive = props.active === variation.databaseId;
        const style = isActive && { borderColor: '#000' };
        return (
          <div
            key={variation.id}
            className={classes.productVariation}
            style={{ ...style }}
            onClick={() => props.onChange && props.onChange(variation)}
          >
            <span
              className={classes.productVariationRadio}
              style={{ ...style }}
            >
              {isActive && <span></span>}
            </span>
            <div className={classes.productVariationValues}>
              <Typography variant="body1">
                {variation.attributes.nodes[0].value}
              </Typography>
              <ProductPrices
                regular={variation.regularPrice}
                sale={variation.salePrice}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
