import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Page } from 'components/shared/Page/Page';
import { useGetPageBySlugQuery } from '../../graphql/generated/generated';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { HtmlContent } from 'components/shared/HTMLContent/HTMLContent';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  defaultPage: {
    display: 'flex',
    justifyContent: 'center',
    '& .HTMLContent p ': {
      marginBottom: 10,
      lineHeight: '1.5em',
      textAlign: 'center',
    },
  },
  image: {
    width: '100%',
    maxWidth: '420px',
  },
  content: {
    width: '100%',
    maxWidth: '50%',
    paddingLeft: '50px',
  },
});

interface Props {}

export const BrandPhotography: React.FC<RouteComponentProps<Props>> = (
  props
) => {
  const classes = useStyles();
  const query = useGetPageBySlugQuery({
    variables: {
      slug: 'brand-photography',
    },
  });

  const content = query.data?.pageBy?.content;
  const title = query.data?.pageBy?.title;
  const image = query.data?.pageBy?.featuredImage?.node?.sourceUrl;

  return (
    <Page animate withContainer>
      <div className={classes.defaultPage}>
        <div className={classes.image}>
          <Typography
            style={{ marginBottom: 30, textAlign: 'center' }}
            variant="h5"
          >
            {query.loading ? (
              <Skeleton
                width="100%"
                height={500}
                style={{ transform: 'none' }}
              />
            ) : (
              <>{image && <img src={`${image}`} alt={`${title}`} />}</>
            )}
          </Typography>
        </div>
        <div className={classes.content}>
          <Typography
            style={{ marginBottom: 30, textAlign: 'center' }}
            variant="h5"
          >
            {query.loading ? (
              <Skeleton
                height={50}
                style={{ maxWidth: 200, margin: '0 auto', transform: 'none' }}
              />
            ) : (
              title
            )}
          </Typography>

          {query.loading ? (
            <>
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
              <Skeleton
                width="100%"
                height={20}
                style={{ transform: 'none' }}
              />
            </>
          ) : (
            <HtmlContent content={content} />
          )}
        </div>
      </div>
    </Page>
  );
};
