import React from "react";
import classNames from "classnames";
import MUIAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  alert: {
    margin: "5px 0",
  },
});

interface Props extends AlertProps {}

export const Alert = (props: Props) => {
  const classes = useStyles();

  const { children, className, ...originalProps } = props;

  return (
    <MUIAlert
      {...originalProps}
      className={classNames([classes.alert, className])}
    >
      {children}
    </MUIAlert>
  );
};
