import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export type CheckoutFormik = ReturnType<typeof useCheckoutFormik>;

export interface CheckoutFields {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  address: string | null;
  country: string | null;
  city: string | null;
  zip: string | null;
}

interface UseCheckoutFormOptions {
  initialValues?: CheckoutFields;
  onSubmit: (
    values: CheckoutFields,
    formikHelpers: FormikHelpers<CheckoutFields>
  ) => Promise<any>;
}

const CheckoutSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required!'),
  lastName: Yup.string().required('This field is required!'),
  address: Yup.string().required('This field is required!'),
  email: Yup.string()
    .email('Email is not valid!')
    .required('This field is required!'),
  country: Yup.string().required('This field is required!'),
  city: Yup.string().required('This field is required!'),
  zip: Yup.string().required('This field is required!'),
});

export const useCheckoutFormik = (options: UseCheckoutFormOptions) => {
  return useFormik<CheckoutFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      country: '',
      city: '',
      zip: '',
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CheckoutSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LoginFormik = ReturnType<typeof useCheckoutFormik>;
