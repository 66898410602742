import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { getLocalValues, setLocalValue } from 'lib/helpers/localStorageHelpers';

export const handleWooSession = (token: string | null) => {
  const { wooSession } = getLocalValues();

  if (!token || wooSession === token) {
    return;
  }

  setLocalValue('wooSession', token);
};

export const afterwareLink = new ApolloLink(
  (operation: Operation, forward: NextLink) =>
    forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      handleWooSession(headers.get('woocommerce-session'));

      return response;
    })
);
