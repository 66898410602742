import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ProductListItemFragment,
  useGetProductsQuery,
} from 'graphql/generated/generated';
import { ProductListItem } from 'components/product/ui/ProductListItem';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';

export const ProductsList = () => {
  const { data, loading, error } = useGetProductsQuery();

  if (error) return <ErrorMessage error={error} />;

  const courses = (data?.products?.nodes || []) as ProductListItemFragment[];

  return (
    <Grid container spacing={4}>
      {(loading ? Array.from(new Array(6)) : courses)?.map(
        (item: ProductListItemFragment, index: number) => {
          return (
            <ProductListItem
              key={`course-list-item-${index}`}
              loading={loading}
              item={item}
            />
          );
        }
      )}
    </Grid>
  );
};
