import React from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props extends DialogProps {
  hideActions?: boolean;
  dividers?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;

  onConfirm?: () => void;
}

const Modal = (props: Props) => {
  const {
    hideActions,
    confirmLabel,
    cancelLabel,
    maxWidth,
    onConfirm,
    ...rest
  } = props;

  const handleOnClose = () => {
    if (rest.onClose) {
      rest.onClose({}, "escapeKeyDown");
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog {...rest} fullWidth maxWidth={maxWidth || "sm"}>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers={rest.dividers}>{props.children}</DialogContent>
      <DialogActions hidden={hideActions}>
        <Button color="secondary" onClick={handleOnClose}>
          {cancelLabel || "Anulo"}
        </Button>
        <Button color="primary" autoFocus onClick={handleConfirm}>
          {confirmLabel || "Në rregull"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Modal;
